import { useState } from "react";
import { auth, firestore } from "../firebase";
import Swal from "sweetalert2";

export default function AddEmployee() {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    birthDate: "",
    hireDate: "",
    position: "",
    department: "",
    gender: "",
    maritalStatus: "",
    admin: false,
    id: "",
    sickday: 0,
    vacationday: 0,
    parentalLeave: 0,
    unpaid: 0,
    otherleave: 0,
    specificRole: "",
    active: true,
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };


  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const res = await auth.createUserWithEmailAndPassword(
        user.email,
        user.password
      );
      await firestore
        .collection("users")
        .doc(res.user.uid)
        .set({
          ...user,
          hireDateValue: new Date(user.hireDate).valueOf(),
          birthDateValue: new Date(user.birthDate).valueOf(),
          TimeStamp: new Date().valueOf(),
          uid: res.user.uid,
        });
      Swal.fire({
        title: "Success",
        text: "User added successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: error.message,
        icon: "error",
        confirmButtonText: "OK",
      });
    }  
  };

  const onClickSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to add this user?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, add it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleAdd(e);
      }
    });
  };

  return (
    <div>
      <div className="justify-center h-max-screen items-center flex overflow-x-hidden overflow-y-auto   outline-none focus:outline-none">
        <div className="relative my-6 mx-10  ">
          <div className="border-0 rounded-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center mb-4">
              <h2 className="text-3xl mt-2 font-bold">User Data</h2>
            </div>
            <form className="w-full xl:w-[1000px]">
              <div className="flex mb-2 gap-2 ">
                <div class="w-8 h-8 rounded-full flex justify-center items-center font-semibold bg-blue-600 text-white ">
                  1
                </div>
                <div className="font-bold text-xl mt-[1px]">
                  Personal Details
                </div>
              </div>
              <div className="border-2 border-gray-300 p-3 rounded-lg mb-5">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-first-name"
                    >
                      First Name
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="John"
                      name="firstName"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-last-name"
                    >
                      Last Name
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="Doe"
                      name="lastName"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 ">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-password"
                    >
                      Email
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-username"
                      type="email"
                      placeholder="John@gmail.com"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-password"
                    >
                      Password
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-password"
                      type="text"
                      placeholder="******************"
                      name="password"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-4">
                  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-city"
                    >
                      Birth Date
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-birthdate"
                      type="date"
                      placeholder="01/01/1990"
                      name="birthDate"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-martial-status"
                    >
                      Marital Status
                    </label>
                    <div class="relative">
                      <select
                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-martial-status"
                        name="maritalStatus"
                        onChange={handleChange}
                      >
                        <option>Select status</option>
                        <option>Single</option>
                        <option>Married</option>
                        <option>Divorced</option>
                        <option>Widowed</option>
                      </select>
                    </div>
                  </div>
                  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-gender"
                    >
                      Gender
                    </label>
                    <div class="relative">
                      <select
                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-martial-status"
                        name="gender"
                        onChange={handleChange}
                      >
                        <option>Select gender</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mb-2 gap-2 ">
                <div class="w-8 h-8 rounded-full flex justify-center items-center font-semibold bg-blue-600 text-white ">
                  2
                </div>
                <div className="font-bold text-xl ">Job Details</div>
              </div>
              <div className="border-2 border-gray-300 p-3 rounded-lg mb-5">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-city"
                    >
                      Hire Date
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-hiredate"
                      type="date"
                      placeholder="01/01/1990"
                      name="hireDate"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-martial-status"
                    >
                      Position
                    </label>
                    <div class="relative">
                      <select
                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-martial-status"
                        name="position"
                        onChange={handleChange}
                      >
                        <option>Select Position</option>
                        <option>Developer</option>
                        <option>Accounting</option>
                        <option>Marketing</option>
                        <option>Photograph</option>
                        <option>Designer</option>
                      </select>
                    </div>
                  </div>
                  <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-department"
                    >
                      Department
                    </label>
                    <div class="relative">
                      <select
                        class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="grid-department"
                        name="department"
                        onChange={handleChange}
                      >
                        <option>Select Department</option>
                        <option>Development</option>
                        <option>Accounting</option>
                        <option>Marketing</option>
                        <option>Photograph</option>
                        <option>Designer</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mb-2 gap-2 ">
                <div class="w-8 h-8 rounded-full flex justify-center items-center font-semibold bg-blue-600 text-white ">
                  3
                </div>
                <div className="font-bold text-xl  ">Contact</div>
              </div>
              <div className="border-2 border-gray-300 p-3 rounded-lg ">
                <div class=" -mx-3 mb-6">
                  <div class=" px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-first-name"
                    >
                      Address
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-email"
                      type="text"
                      placeholder="701 April road"
                      name="address"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="mt-4 px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-phone"
                    >
                      Phone
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-phone"
                      type="text"
                      placeholder="0999999999"
                      name="phone"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="flex mb-2 gap-2 mt-5 ">
                <div class="w-8 h-8 rounded-full flex justify-center items-center font-semibold bg-blue-600 text-white ">
                  4
                </div>
                <div className="font-bold text-xl  ">Emergency Contact</div>
              </div>
              <div className="border-2 border-gray-300 p-3 rounded-lg ">
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-first-name"
                    >
                      First Name
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Jane"
                      name="EmergencyFirstName"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-last-name"
                    >
                      Last Name
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="Doe"
                      name="EmergencyLastName"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div class="flex flex-wrap -mx-3 mb-6">
                  <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-first-name"
                    >
                      Relationship to Contact
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-first-name"
                      type="text"
                      placeholder="Father"
                      name="EmergencyRole"
                      onChange={handleChange}
                    />
                  </div>
                  <div class="w-full md:w-1/2 px-3">
                    <label
                      class="block uppercase tracking-wide text-gray-700 text-md font-bold mb-2"
                      for="grid-last-name"
                    >
                      Phone
                    </label>
                    <input
                      class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-last-name"
                      type="text"
                      placeholder="099999999"
                      name="EmergencyPhone"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className="flex items-center justify-center p-2 mt-4  rounded-b">
              <button
                className="!bg-emerald-500 text-white hover:!bg-emerald-800 font-bold uppercase text-sm px-8 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150"
                type="button"
                onClick={onClickSubmit}
              >
                Submit
              </button>
              {/* <button
                className="!bg-red-500 text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1  ease-linear transition-all duration-150 ml-3"
                onClick={handleClear}
              >
                Clear
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
