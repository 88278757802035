import { useEffect, useState } from "react";
import { getUserById } from "../model/user";

export default function Employee() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var id = url.searchParams.get("id");
    getUserById(id).then((user) => {
      setUser(user);
    });
  }, []);



  return (
    <div className="pt-10 mx-10 pb-10">
      <div>
        <h1 className="text-3xl font-bold ml-4 bg-white ">User Data</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-10">
        <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit   ">
          <div>
            <div className="w-full">
              <div className="flex">
                <p className=" text-2xl font-bold">Personal Details</p>
              </div>
              <div className="mt-4 ">
                <form className=" gap-4 ">
                  <div>
                    <div className="md:flex md:items-center mb-6 ">
                      <div className="md:w-1/3">
                        <label className="block   font-bold md:text-right mb-1 md:mb-0 pr-4">
                          First Name
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          type="text"
                          value={user?.firstName}
                          name="firstName"
                        // onChange={onChangeEdit}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-1/3">
                        <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                          Last Name
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          type="text"
                          value={user?.lastName}
                          name="lastName"
                        // onChange={onChangeEdit}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-1/3">
                        <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                          Email
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          type="text"
                          value={user?.email}
                          name="email"
                        // onChange={onChangeEdit}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-1/3">
                        <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                          Password
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          type="text"
                          value={user?.password}
                          name="password"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-1/3">
                        <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                          Gender
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          id="grid-martial-status"
                          name="gender"
                          value={user?.gender}
                        // onChange={onChangeEdit}
                        />
                      </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-1/3">
                        <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                          Marital Status
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          id="grid-martial-status"
                          name="maritalStatus"

                          value={user?.maritalStatus}
                        // onChange={onChangeEdit}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="md:flex md:items-center mb-6">
                      <div className="md:w-1/3">
                        <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                          Birth Date
                        </label>
                      </div>
                      <div className="md:w-2/3">
                        <input
                          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                          id="grid-birthdate"
                          type="text"
                          name="birthDate"

                          value={user?.birthDate}
                        // onChange={onChangeEdit}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div >
          <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit  ">
            <div >
              <div className="w-full">
                <div className="flex">
                  <p className=" text-2xl font-bold">Job details</p>
                </div>
                <div className="mt-4  ">
                  <form className=" gap-4 ">
                    <div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                          <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Start Date
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                            id="grid-birthdate"
                            type="text"
                            name="hireDate"
                            value={user?.hireDate}
                          // onChange={onChangeEdit}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                          <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Position
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                            id="grid-martial-status"
                            name="position"
                            value={user?.position}
                          // onChange={onChangeEdit}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                          <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Department
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                            id="grid-martial-status"
                            name="department"
                            value={user?.department}
                          // onChange={onChangeEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit mt-3  ">
            <div>
              <div className="w-full">
                <div className="flex">
                  <p className=" text-2xl font-bold">Contact</p>
                </div>
                <div className="mt-4  ">
                  <form className=" gap-4 ">
                    <div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                          <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Current Address
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                            id="grid-birthdate"
                            type="text"
                            name="address"
                            value={user?.address}
                          // onChange={onChangeEdit}
                          />
                        </div>
                      </div>
                      <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/3">
                          <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                            Phone
                          </label>
                        </div>
                        <div className="md:w-2/3">
                          <input
                            className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                            id="grid-birthdate"
                            type="text"
                            name="phone"
                            value={user?.phone}
                          // onChange={onChangeEdit}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit mt-10  pr-10">
        <div>
          <div className="w-full">
            <div className="">
              <p className=" text-2xl font-bold">Emergency Contact</p>
            </div>
            <div className="mt-4 jusitfy-left gap-10  ">
              <form className=" gap-4 ">
                <div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        First Name
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="EmergencyFirstName"
                        value={user?.EmergencyFirstName}
                      // onChange={onChangeEdit}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Last Name
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="EmergencyLastName"
                        value={user?.EmergencyLastName}
                      // onChange={onChangeEdit}
                      />
                    </div>
                  </div>
                </div>
              </form>
              <form className=" gap-4 ">
                <div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Relationship to User
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="EmergencyRole"
                        value={user?.EmergencyRole}
                      // onChange={onChangeEdit}
                      />
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Phone
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="EmergencyPhone"
                        value={user?.EmergencyPhone}
                      // onChange={onChangeEdit}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit mt-10  pr-10">
        <div>
          <div className="w-full">
            <div className="">
              <p className=" text-2xl  font-bold">Leave Days</p>
            </div>
            <div className="mt-4 jusitfy-left gap-10 flex  ">
              <form className=" gap-4 ">
                <div>
                  <div className="md:flex md:items-center mb-6">
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Sick day
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="EmergencyFirstName"
                        value={user?.sickday}
                      // onChange={onChangeEdit}
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Parental Leave
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="parentalLeave"
                        value={user?.parentalLeave}
                      // onChange={onChangeEdit}
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Unpaid
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="unpaid"
                        value={user?.unpaid}
                      // onChange={onChangeEdit}
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Vacation
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="vacationday"
                        value={user?.vacationday}
                      // onChange={onChangeEdit}
                      />
                    </div>
                    <div className="md:w-1/3">
                      <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                        Other
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                        id="grid-birthdate"
                        type="text"
                        name="otherleave"
                        value={user?.otherleave}
                      // onChange={onChangeEdit}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
