import React from "react";
import moment from "moment";
import { useState, useEffect } from "react";
import { getUserById } from "../model/user";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { FaRegUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { firestore } from "../firebase";
import Loading from "../components/loading";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import { FaBirthdayCake } from "react-icons/fa";
import { GoBell } from "react-icons/go";

import { MdCancel } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { AiTwotoneCalendar } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { SiGooglesheets } from "react-icons/si";
import { MdWorkOff } from "react-icons/md";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


export default function CheckIn() {
  const { currentUser } = useContext(AuthContext);
  const [user, setUser] = useState([
    {
      name: "",
      email: "",
      phone: "",
      address: "",
    },
  ]);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(moment().format("LTS"));
  const [meetings, setMeeting] = useState([]);
  const [allDayOff, setAllDayOff] = useState([]);
  let today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());
  setInterval(function () {
    var todaymoment = moment().format("LTS");
    setTime(todaymoment);
  }, 1000);

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  const isBirthday = (birthdate, selectedDay) => {
    let birthdateDay = parseISO(birthdate);
    return (
      birthdateDay.getDate() === selectedDay.getDate() &&
      birthdateDay.getMonth() === selectedDay.getMonth()
    );
  };

  let birthdays = meetings.filter((meeting) =>
    isBirthday(meeting.BirthDate, selectedDay)
  );

  const isAniversary = (aniversary, selectedDay) => {
    let aniversaryDay = parseISO(aniversary);
    return (
      aniversaryDay.getDate() === selectedDay.getDate() &&
      aniversaryDay.getMonth() === selectedDay.getMonth()
    );
  };

  let aniversaries = meetings.filter((meeting) =>
    isAniversary(meeting.HireDate, selectedDay)
  );

  const isDayOff = (dayOff, selectedDay) => {
    let dayOffDay = parseISO(dayOff);
    return (
      dayOffDay.getDate() === selectedDay.getDate() &&
      dayOffDay.getMonth() === selectedDay.getMonth()
    );
  };

  let dayOffs = allDayOff.filter((dayOff) => isDayOff(dayOff.StartDate, selectedDay));

  useEffect(() => {
    setLoading(true);
    const getUserData = async () => {
      const userData = await getUserById(currentUser.uid);
      // console.log(userData);
      setUser(userData);
      setLoading(false);
    };
    getUserData();
    const fetchData = async () => {
      let list = [];
      try {
        const getUsersRef = firestore.collection("users");
        const snapshot = await getUsersRef.get();
        snapshot.forEach((doc) => {
          list.push({
            ...doc.data(),
            id: doc.id,
            fullname: doc.data().firstName + " " + doc.data().lastName,
            BirthDate: moment(doc.data().birthDateValue).format(
              "YYYY-MM-DDTHH:mm"
            ),
            HireDate: moment(doc.data().hireDateValue).format(
              "YYYY-MM-DDTHH:mm"
            ),
            role: doc.data().admin,
          });
        });
        setMeeting(list);



        const getAllDayOffRef = firestore.collection("dayOff");
        const snapshotAllDayOff = await getAllDayOffRef.get();
        let listAllDayOff = [];
        snapshotAllDayOff.forEach((doc) => {
          listAllDayOff.push({
            ...doc.data(),
            id: doc.id,
            StartDate: moment(doc.data().startDate).format(
              "YYYY-MM-DDTHH:mm"
            ),
            EndDate: moment(doc.data().endDate).format(
              "YYYY-MM-DDTHH:mm"
            ),
          });
        });
        setAllDayOff(listAllDayOff.filter((dayOff) => dayOff.status === "Approved"));
        setLoading(false);

      } catch (error) {
        console.log(error);
      }
    };
    if (meetings.length === 0) {
      fetchData();

    }
  }, []);


  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/user";
      }
    });
  };



  return (
    <>
      <div className="flex items-center justify-center max-w-screen min-h-screen bg-gradient-to-tl from-blue-200 to-blue-900 ">
        {loading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <>
            {showCalendar ? (
              <><div className="justify-center h-screen  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="p-4 bg-white shadow-lg rounded-xl">
                  <div className="flex justify-between">
                    <div className="text-2xl font-bold mb-3">
                      Calendar
                    </div>
                    <div className="text-2xl font-bold mb-3 cursor-pointer "
                      onClick={() => setShowCalendar(false)}
                    >
                      <IoMdClose />
                    </div>
                  </div>
                  <div className=" max-w-lg  md:max-w-4xl  border-2 rounded-xl border-gray-300 p-3">
                    <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
                      <div className="md:pr-14">
                        <div className="flex items-center">
                          <h2 className="flex-auto font-semibold text-gray-900">
                            {format(firstDayCurrentMonth, "MMMM yyyy")}
                          </h2>
                          <button
                            type="button"
                            onClick={previousMonth}
                            className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Previous month</span>
                            <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                          </button>
                          <button
                            onClick={nextMonth}
                            type="button"
                            className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                          >
                            <span className="sr-only">Next month</span>
                            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                          </button>
                        </div>
                        <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
                          <div>S</div>
                          <div>M</div>
                          <div>T</div>
                          <div>W</div>
                          <div>T</div>
                          <div>F</div>
                          <div>S</div>
                        </div>
                        <div className="grid grid-cols-7 mt-2 text-sm">
                          {days.map((day, dayIdx) => (
                            <div
                              key={day.toString()}
                              className={classNames(
                                dayIdx === 0 && colStartClasses[getDay(day)],
                                "py-1.5"
                              )}
                            >
                              <button
                                type="button"
                                onClick={() => setSelectedDay(day)}
                                className={classNames(
                                  isEqual(day, selectedDay) && "!text-white",
                                  !isEqual(day, selectedDay) &&
                                  isToday(day) &&
                                  "!text-red-500",
                                  !isEqual(day, selectedDay) &&
                                  !isToday(day) &&
                                  isSameMonth(day, firstDayCurrentMonth) &&
                                  "!text-gray-900",
                                  !isEqual(day, selectedDay) &&
                                  !isToday(day) &&
                                  !isSameMonth(day, firstDayCurrentMonth) &&
                                  "!text-gray-400",
                                  isEqual(day, selectedDay) &&
                                  isToday(day) &&
                                  "!bg-red-500",
                                  isEqual(day, selectedDay) &&
                                  !isToday(day) &&
                                  "!bg-gray-900",
                                  !isEqual(day, selectedDay) && "!hover:bg-gray-200",
                                  (isEqual(day, selectedDay) || isToday(day)) &&
                                  "font-semibold",
                                  "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                                )}
                              >
                                <time dateTime={format(day, "yyyy-MM-dd")}>
                                  {format(day, "d")}
                                </time>
                              </button>

                              <div className="w-1 h-1 mx-auto mt-1">
                                {((meetings.some(
                                  (meeting) =>
                                    isBirthday(meeting.BirthDate, day) ||
                                    isAniversary(meeting.HireDate, day)

                                )) || (allDayOff.some(
                                  (dayOff) =>
                                    isDayOff(dayOff.StartDate, day)
                                ))) && (
                                    <div className="w-1 h-1 rounded-full !bg-sky-500"></div>
                                  )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <section className="mt-12 md:mt-0 md:pl-14">
                        <h2 className="font-semibold text-xl text-gray-900">
                          Event on{" "}
                          <time dateTime={format(selectedDay, "yyyy-MM-dd")}>
                            {format(selectedDay, "MMM dd, yyy")}
                          </time>
                        </h2>
                        <ol className="mt-4 space-y-1 text-lg leading-6 text-gray-500 mb-5">
                          {(birthdays.length > 0 || aniversaries.length > 0 || dayOffs.length != 0) ? (
                            // birthdays.map((meeting) => (
                            //   <Meeting meeting={meeting} key={meeting.id} />
                            // ))
                            <>
                              {birthdays.map((meeting) => (
                                <Meeting
                                  meeting={meeting}
                                  key={meeting.id}
                                  selectedDay={selectedDay}
                                />
                              ))}
                              {aniversaries.map((meeting) => (
                                <Birthday
                                  meeting={meeting}
                                  key={meeting.id}
                                  selectedDay={selectedDay}
                                />
                              ))}
                              {dayOffs.map((dayOff) => (
                                <DayOff
                                  dayOff={dayOff}
                                  key={dayOff.id}
                                  selectedDay={selectedDay}
                                />
                              ))}
                            </>
                          ) : (
                            <p>No event on today.</p>
                          )}
                        </ol>
                      </section>
                    </div>
                  </div>
                  <div>
                    <div></div>
                  </div>
                </div>
              </div>
                <div className="opacity-50 fixed inset-0 z-40 bg-black"></div></>

            ) : (<div></div>)}
            <div className=" px-[10px] s5m:px-[20px] md:px-[80px] pt-[10px] md:pt-[30px] md:pb-[40px] pb-[10px]  text-left bg-white shadow-lg rounded-xl">
              {/* <h3 className="text-4xl font-bold text-center">Sign In</h3> */}
              <div className="font-bold md:text-5xl text-3xl text-center md:mt-0 mt-3 ">{time}</div>
              <div className="font-semibold text-center md:text-2xl text-lg mt-2 ">
                {moment().format("dddd, ll")}
              </div>
              <form action="" >
                <div className="mt-10">
                  <div className="flex gap-3 border-2 border-gray-400 rounded-[10px] md:p-6 s5m:p-3 ">
                    {user.admin === true ? (
                      <div className="flex justify-between w-100  ">
                        <div className="flex gap-3">
                          <div className="mt-4">
                            <FaRegUserCircle className="text-5xl text-gray-600 s5m:block hidden " />
                          </div>
                          <div className="font-semibold md:text-xl  s5m:text-md text-sm md:mt-5 s5m:mt-5 mt-3  ">
                            <div>
                              {user.firstName} {user.lastName}{" "}
                            </div>
                            <div>{user.email}</div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="text-center ml-10 s5m:mt-2 mt-1  cursor-pointer text-[10px] s5m:text-[15px] text-white bg-red-800 hover:bg-red-700 rounded-[10px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-2"
                              onClick={() => window.location.href = user.specificRole !== "Client Admin" ? ` /admin/dashboard` : `/admin/timesheetlist`}
                          >
                            Admin
                          </div>
                          <div
                            className="text-center ml-10 mb-1 s5m:mt-2 mt-1  cursor-pointer text-[10px] s5m:text-[15px] text-white bg-gray-900 hover:bg-gray-700 rounded-[10px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-2"
                            onClick={handleLogout}
                          >
                            Log out
                          </div>
                        </div>
                      </div>) : (<div className="flex justify-between w-100  ">
                        <div className="flex gap-3">
                          <div className="mt-2">
                            <FaRegUserCircle className="text-5xl text-gray-600 s5m:block hidden " />
                          </div>
                          <div className="font-semibold md:text-xl s5m:text-md text-sm md:mt-0 s5m:mt-2  ">
                            <div>
                              {user.firstName} {user.lastName}{" "}
                            </div>
                            <div>{user.email}</div>
                          </div>
                        </div>
                        <div>
                          <div
                            className="text-center ml-10 s5m:mt-2 mt-1  cursor-pointer text-[10px] s5m:text-[15px] text-white bg-gray-900 hover:bg-gray-700 rounded-[10px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-2"
                            onClick={handleLogout}
                          >
                            Log out
                          </div>
                        </div>
                      </div>)}
                  </div>
                </div>
                <div className="flex gap-4">
                  <div
                    className="px-6 font-semibold py-3 mt-4 text-white flex items-center justify-center gap-2 bg-blue-800 text-center rounded-[10px] hover:bg-blue-900 w-100 cursor-pointer"
                    onClick={() => setShowCalendar(!showCalendar)}
                  >
                    <span><AiTwotoneCalendar size={20} /></span> Calendar
                  </div>
                  <div
                    className="px-6 font-semibold py-3 mt-4 text-white bg-blue-600 flex items-center justify-center gap-2 rounded-[10px] hover:bg-blue-700 w-100 cursor-pointer"
                    onClick={() => (window.location.href = "/user/profile")}
                  >
                    <span><FaUser size={18} /></span>Profile
                  </div>
                </div>
                <div>
                  <div
                    className="px-6 font-semibold py-3 mt-4 text-white bg-green-600 flex items-center justify-center gap-2 rounded-[10px] hover:bg-green-700 w-100 cursor-pointer"
                    onClick={() => (window.location.href = "/user/timesheet")}
                  >
                    <span><SiGooglesheets size={20} /></span>Time Sheet
                  </div>
                </div>
                <div>
                  <div
                    className="px-6 font-semibold py-3 mt-4 text-white bg-gray-500 flex items-center justify-center gap-2 rounded-[10px] hover:bg-gray-600 w-100 cursor-pointer"
                    onClick={() => (window.location.href = "/user/shift")}
                  >
                    <span><MdWorkOff size={20} /></span>Day Off
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </div>
    </>
  );
}

function Meeting({ meeting }) {
  return (
    <li className="flex items-center px-4 py-3 space-x-4 group rounded-xl focus-within:bg-gray-100 bg-gray-100">
      <div>
        <FaBirthdayCake size={35} className="text-orange-400" />
      </div>
      <div className="flex-auto text-md">
        <p className="text-gray-900 ">
          {" "}
          <span className="font-semibold ">{meeting.fullname} </span>
          has birthday today.
        </p>
        <p className="text-gray-900">Wish them well!</p>
      </div>
    </li>
  );
}

function DayOff({ dayOff }) {
  return (
    <li className="flex mt-3 items-center px-4 py-3 space-x-4 group rounded-xl focus-within:bg-gray-100 bg-gray-100">
      <div>
        <MdCancel size={35} className="text-red-500" />
      </div>
      <div className="flex-auto text-md">
        <p className="text-gray-900 ">
          {" "}
          <span className="font-semibold ">{dayOff.name} </span>
          leaves today.
        </p>
      </div>
    </li>
  );
}

function Birthday({ meeting, selectedDay }) {
  // console.log(new Date(selectedDay).getFullYear() - new Date(meeting.HireDate).getFullYear());
  return (
    <li className="flex mt-3 items-center px-4 py-3 space-x-4 group rounded-xl focus-within:bg-gray-100 bg-gray-100">
      <div>
        <GoBell size={35} className="text-blue-400" />
      </div>
      <div className="flex-auto text-md">
        <p className="text-gray-900 ">
          {" "}
          <span className="font-semibold ">{meeting.fullname} </span>
          {new Date(selectedDay).getFullYear() -
            new Date(meeting.HireDate).getFullYear() ===
            0 ? (
            <span>has joined today.</span>
          ) : (
            <span>
              has joined{" "}
              {new Date(selectedDay).getFullYear() -
                new Date(meeting.HireDate).getFullYear()}{" "}
              {new Date(selectedDay).getFullYear() -
                new Date(meeting.HireDate).getFullYear() ===
                "1"
                ? "year"
                : "years"}{" "}
              ago.
            </span>
          )}
        </p>
        <p className="text-gray-900">
          {new Date(selectedDay).getFullYear() -
            new Date(meeting.HireDate).getFullYear() ===
            0
            ? "Welcome to the Marketing Bear!"
            : "Wish them well!"}
        </p>
      </div>
    </li>
  );
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];
