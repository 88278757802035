import React, { useEffect } from "react";
import { useState } from "react";
import Loading from "../components/loading";
import { firestore } from "../firebase";
import Swal from "sweetalert2";
import { getUserById } from "../model/user";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import {AiTwotoneEdit} from "react-icons/ai";
import {MdOutlineDeleteForever} from "react-icons/md";

export default function AdminList() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    birthDate: "",
    hireDate: "",
    position: "",
    department: "",
    gender: "",
    maritalStatus: "",
    admin: false,
    id: "",
    sickday: 0,
    vacationday: 0,
    parentalLeave: 0,
    unpaid: 0,
    otherleave: 0,
    specificRole: "",
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const getUsersRef = firestore.collection("users");
        const snapshot = await getUsersRef.get();
        snapshot.forEach((doc) => {
          list.push({
            ...doc.data(),
            id: doc.id,
            fullname: doc.data().firstName + " " + doc.data().lastName,
            BirthDate: moment(doc.data().birthDateValue).format("MM/DD/YYYY"),
            HireDate: moment(doc.data().hireDateValue).format("MM/DD/YYYY"),
          });
        });
        // console.log(list);
        setItems(list.filter((item) => item.admin === true && item.active === true));
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    if (items.length === 0) {
      fetchData();
    }
  }, []);

  const handleEdit = async (e) => {
    e.preventDefault();
    // setShowModalEdit(true);
    try {
      const editUserRef = firestore.collection("users").doc(user.id);
      await editUserRef.update({
        ...user,
        hireDateValue: new Date(user.hireDate).valueOf(),
        birthDateValue: new Date(user.birthDate).valueOf(),
      });
      Swal.fire({
        title: "Success",
        text: "User updated successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onClickEdit = async (id) => {
    const user = await getUserById(id);
    // console.log(user);
    setUser({ ...user, id: id });
    setShowModalEdit(true);
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: "warning",
        text: "Are you sure, you want remove admin role for this user?",
        showCancelButton: true,
        backdrop: "rgba(120,120,120,0.5)",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const changeAdmin = firestore.collection("users").doc(id);
          await changeAdmin.update({
            admin: false,
            specificRole: "",
          });
          setItems(items.filter((item) => item.id !== id));
          Swal.fire("Remove!", "User has been remove admin role", "success");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const { SearchBar, ClearSearchButton } = Search;

  const button = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="flex gap-1">
        <button
          className="btn btn-primary  text-sm"
          onClick={() => {
            onClickEdit(row.id);
          }}
        >
          <div className="flex ">
            <AiTwotoneEdit size={20} />
          </div>
        </button>
        <button
          className="btn btn-danger text-sm"
          onClick={() => {
            handleDelete(row.id);
          }}
        >
          <div className="flex ">
            <MdOutlineDeleteForever size={20} />
          </div>
        </button>
      </div>
    );
  };

  const UserColumns = [
    {
      dataField: "fullname",
      text: "Name",
      sort: true,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "specificRole",
      text: "Role",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      formatter: button,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: items.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        {showModalEdit ? (
          <>
            <div className="justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto  ">
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                    <h2 className="text-2xl font-semibold ml-2">Admin data</h2>
                  </div>
                  <form class="w-full  p-1 px-3">
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          First Name
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-first-name"
                          type="text"
                          placeholder="Jane"
                          name="firstName"
                          onChange={handleChange}
                          value={user.firstName}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Last Name
                        </label>
                        <input
                          class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Doe"
                          name="lastName"
                          onChange={handleChange}
                          value={user.lastName}
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full  px-3">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-department"
                        >
                          Specific Role
                        </label>
                        <div class="relative">
                          <select
                            class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-specificRole"
                            name="specificRole"
                            onChange={handleChange}
                            value={user?.specificRole}
                          >
                            <option>Select Specific Role</option>
                            <option>Hr Admin</option>
                            <option>Client Admin</option>
                            <option>Super Admin</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                    <button
                      className="text-red-500 !bg-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModalEdit(false)}
                    >
                      Close
                    </button>
                    <button
                      className="!bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleEdit}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}

        <div className="mt-10 mx-10 flex justify-between">
          <div className="text-3xl font-bold">Admin</div>
        </div>
        <div className="mt-10 mx-10">
          <ToolkitProvider
            keyField="id"
            data={items}
            columns={UserColumns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} className="!rounded-[5px] " />
                <ClearSearchButton
                  {...props.searchProps}
                  className="btn border ml-3 p-2 hover:bg-gray-600 hover:text-white text-sm "
                />
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  striped
                  hover
                  condensed
                  classes="mt-3 "
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  }
}
