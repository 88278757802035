import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { getAllTask } from "./../model/timeSheet";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"; // Search,
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import Logo from "../assets/logoMB1.png";
import Loading from "../components/loading";
import { getAllClients } from "../model/client";

export default function TaskSheetList() {
  const [items, setItems] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState("");
  const [time, setTime] = useState("");
  const [year, setYear] = useState("");

  const monthList = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
  }
  function padToTwoDigits(num) {
    return num.toString().padStart(2, "0");
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  useEffect(() => {
    setLoading(true);
    var url_string = window.location.href;
    var url = new URL(url_string);
    var clientId = url.searchParams.get("client");
    var project = url.searchParams.get("project");
    var month = url.searchParams.get("month");
    var monthText = month.split("-")[0];
    var yearText = month.split("-")[1];
    setTime(monthList.find((x) => x.value === monthText).label);
    setYear(yearText);
    const fetchData = async () => {
      let list = [];
      const data = await getAllTask();
      const clientData = await getAllClients();
      setClient(clientData.find((x) => x.id === clientId).name);
      // console.log(data.filter((x) => x.clientId === clientId));
      data.forEach((item) => {
        var someDate = new Date(Date.now());
        var add14Days = someDate.setDate(someDate.getDate() + 14);
        var add120Days = someDate.setDate(someDate.getDate() + 106);
        list.push({
          ...item,
          clientId: item.clientId,
          projectId: item.projectId,
          dateTime: moment(item.date).format("DD/MM/YYYY"),
          time:
            (item.hours === ""
              ? "00"
              : item.hours < 10
              ? "0" + item.hours
              : item.hours) +
            ":" +
            (item.minutes === ""
              ? "00"
              : item.minutes < 10
              ? "0" + item.minutes
              : item.minutes),
          country: clientData.find((x) => x.name === item.client).country,
          currency: clientData.find((x) => x.name === item.client).currency,
          rate: clientData.find((x) => x.name === item.client).rate,
          invoiceDate: moment(new Date(Date.now())).format("DD/MM/YYYY"),
          dueDate:
            item.client === "Michelin ROH Co., Ltd."
              ? moment(new Date(add120Days)).format("DD/MM/YYYY")
              : moment(new Date(add14Days)).format("DD/MM/YYYY"),
          toTalMinutes: Number(item.hours) * 60 + Number(item.minutes),
          unitHours: Number(
            ((Number(item.hours * 60) + Number(item.minutes)) / 60).toFixed(2)
          ),
          total:
            clientData.find((x) => x.name === item.client).country ===
            "Thailand"
              ? (
                  Number(
                    (
                      Number(
                        (
                          (Number(item.hours * 60) + Number(item.minutes)) /
                          60
                        ).toFixed(2)
                      ) *
                      clientData.find((x) => x.name === item.client).rate *
                      (item.taskType === "general" ? 1 : 2)
                    ).toFixed(2)
                  ) +
                  Number(
                    (
                      Number(
                        (
                          (Number(item.hours * 60) + Number(item.minutes)) /
                          60
                        ).toFixed(2)
                      ) *
                      clientData.find((x) => x.name === item.client).rate *
                      0.07 *
                      (item.taskType === "general" ? 1 : 2)
                    ).toFixed(2)
                  )
                ).toFixed(2)
              : Number(
                  (
                    Number(
                      (
                        (Number(item.hours * 60) + Number(item.minutes)) /
                        60
                      ).toFixed(2)
                    ) *
                    clientData.find((x) => x.name === item.client).rate *
                    (item.taskType === "general" ? 1 : 2)
                  ).toFixed(2)
                ).toFixed(2),
          taxTotal:
            clientData.find((x) => x.name === item.client).country ===
            "Thailand"
              ? (
                  Number(
                    (
                      (Number(item.hours * 60) + Number(item.minutes)) /
                      60
                    ).toFixed(2)
                  ) *
                  clientData.find((x) => x.name === item.client).rate *
                  0.07 *
                  (item.taskType === "general" ? 1 : 2)
                ).toFixed(2)
              : 0,
          amountDue: (
            Number(
              ((Number(item.hours * 60) + Number(item.minutes)) / 60).toFixed(2)
            ) *
            clientData.find((x) => x.name === item.client).rate *
            (item.taskType === "general" ? 1 : 2)
          ).toFixed(2),
          quantity: 1,
          taxAmount:
            clientData.find((x) => x.name === item.client).country ===
            "Thailand"
              ? (
                  Number(
                    (
                      (Number(item.hours * 60) + Number(item.minutes)) /
                      60
                    ).toFixed(2)
                  ) *
                  clientData.find((x) => x.name === item.client).rate *
                  0.07 *
                  (item.taskType === "general" ? 1 : 2)
                ).toFixed(2)
              : 0,
          unitAmount:
            clientData.find((x) => x.name === item.client).rate *
            (item.taskType === "general" ? 1 : 2),
          taskDescription: item.task ? item.task : "-",
        });
        setStartDate(moment(new Date(item.date)).format("DD/MM/YYYY"));
        setEndDate(
          item.client === "Michelin ROH Co., Ltd."
            ? moment(new Date(add120Days)).format("DD/MM/YYYY")
            : moment(new Date(add14Days)).format("DD/MM/YYYY")
        );
        setLoading(false);
      });
      setItems(
        list.filter((item) => {
          if (clientId && project && month) {
            return (
              item.clientId === clientId &&
              item.projectId === project &&
              moment(item.date).format("MM-YYYY") === month
            );
          } else if (clientId && project) {
            return item.clientId === clientId && item.projectId === project;
          } else if (clientId && month) {
            return (
              item.clientId === clientId &&
              moment(item.date).format("MM-YYYY") === month
            );
          } else if (project && month) {
            return (
              item.projectId === project &&
              moment(item.date).format("MM-YYYY") === month
            );
          } else if (clientId) {
            return item.clientId === clientId;
          } else if (project) {
            return item.projectId === project;
          } else if (month) {
            return moment(item.date).format("MM-YYYY") === month;
          } else {
            return item;
          }
        })
      );
    };
    fetchData();
  }, []);




  const typeFormatter = (cell, row) => {
    if (cell === "general") {
      return (
        <div>
          <span className="bg-green-200 py-1 px-[20px] text-green-700 font-medium   rounded-xl text-sm ">
            Normal
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <span className="bg-red-200 py-1 px-[10px] text-red-700 font-medium   rounded-xl text-sm ">
            Emergency
          </span>
        </div>
      );
    }
  };
  

  const dayOffColumns2 = [
    {
      dataField: "dateTime",
      text: "Date",
      sort: true,
      footer: "",
    },
    {
      dataField: "project",
      text: "Project",
      sort: true,
      footer: "",
    },
    {
      dataField: "taskTitle",
      text: "Task Title",
      sort: true,
      footer: "",
    },
    {
      dataField: "taskDescription",
      text: "Task Description",
      sort: true,
      footer: "",
    },
    {
      dataField: "taskType",
      text: "Task Type",
      sort: true,
      footer: "",
      formatter: typeFormatter,
    },
    {
      dataField: "name",
      text: "Responsible Person",
      sort: true,
      footer: "Total",
      
    },
    // {
    //   dataField: "unitAmount",
    //   text: "Unit amount",
    //   sort: true,
    //   footer: "",
    // },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      footer: (columnData) => {
        const total = items.reduce((sum, item) => sum + item.toTalMinutes, 0);
        return <div>{toHoursAndMinutes(total)}</div>;
      },
    },
    // {
    //   dataField: "amountDue",
    //   text: "Amount Due",
    //   sort: true,
    //   footer: (columnData) => {
    //     const total = items.reduce(
    //       (acc, item) => acc + Number(item.amountDue),
    //       0
    //     );
    //     return <div>{total.toFixed(2)}</div>;
    //   },
    // },
    // {
    //   dataField: "taxTotal",
    //   text: "Tax Total",
    //   sort: true,
    //   footer: (columnData) => {
    //     const total = items.reduce(
    //       (acc, item) => acc + Number(item.taxTotal),
    //       0
    //     );
    //     return <div>{total.toFixed(2)}</div>;
    //   },
    // },
    // {
    //   dataField: "total",
    //   text: "Total",
    //   sort: true,
    //   footer: (columnData) => {
    //     const total = items.reduce((acc, item) => acc + Number(item.total), 0);
    //     return <div>{total.toFixed(2)}</div>;
    //   },
    // },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: items.length,
      },
    ],
  };

  return (
    <div className="min-h-screen font-medium">
      {loading ? (
        <div className="!h-screen !justify-center !items-center !align-middle flex">
          <Loading />
        </div>
      ) : (
        <div>
          <div className="lg:!mx-20 mx-5 ">
            <div className="w-fit ">
              <div className="min-w-[100px] ">
                <a
                  href="https://marketingbear.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Logo} alt="" className="w-[150px] " />
                </a>
              </div>
            </div>
            <div className="text-center mt-[-10px] font-bold justify-center flex  text-xl mb-4">
              <div className="bg-blue-700 px-3 py-2 text-white rounded-xl ">
                Task Report
              </div>
            </div>
            <div className="flex gap-2 mt-[-10px] text-lg  font-bold justify-center text-center">
              <div>{client}</div>
              <div>
                ({time} {year})
              </div>
            </div>
            <div className="flex font-bold text-lg  mt-[6px] justify-center text-center">
              Total:{" "}
              {numberWithCommas(
                items
                  .reduce((acc, item) => acc + Number(item.total), 0)
                  .toFixed(2)
              )}{" "}
              {items[0].currency}
            </div>
          </div>
            <div className="mb-10 mt-2 lg:!mx-20 mx-5  table-task">
            <ToolkitProvider
              keyField="id"
              data={items}
              columns={dayOffColumns2}
            >
              {(props) => (
                <div>
                  <BootstrapTable
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    striped
                    hover
                    condensed
                    classes="mt-3 !rounded-lg"
                    filter={filterFactory()}
                    noDataIndication="No Data"
                  />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </div>
      )}
    </div>
  );
}
