// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

//test
// const firebaseApp = firebase.initializeApp({
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: "employee-system-b110a.firebaseapp.com",
//   projectId: "employee-system-b110a",
//   storageBucket: "employee-system-b110a.appspot.com",
//   messagingSenderId: "176943542194",
//   appId: "1:176943542194:web:c4c902026868d704f57eab",
// })

//real

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAmAmSjC7EWqnZfoE6cQvC6Watzcff6EfE",
  authDomain: "mb-management-f6efe.firebaseapp.com",
  projectId: "mb-management-f6efe",
  storageBucket: "mb-management-f6efe.appspot.com",
  messagingSenderId: "331572113525",
  appId: "1:331572113525:web:effac10f2ab2d7d1f9083c",
});

// const app = initializeApp(firebaseConfig);
// export const db = getFirestore(app);
// export const auth = getAuth();

export default firebaseApp;
export const firestore = firebaseApp.firestore();
export const auth = firebaseApp.auth();
