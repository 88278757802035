import { firestore } from "./../firebase";

export const getAllDayOff = async () => {
  try {
    let list = [];
    const dayOffRef = await firestore.collection("dayOff").orderBy("startDate","desc");
    const dayOffData = await dayOffRef.get();
    await dayOffData.forEach(async (doc) => {
      list.push({...doc.data(), id: doc.id});
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const updateStatusDayOffById = async (id, status) => {
  try {
    const dayOffRef = await firestore.collection("dayOff").doc(id);
    await dayOffRef.update({
      status: status,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const plusSickDay = async (id) => {
  try {
    const userRef = await firestore.collection("users").doc(id);
    const userData = await userRef.get();
    const user = userData.data();
    const newSickDay = user.sickday + 1;
    await userRef.update({
      sickday: newSickDay,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const plusVacationDay = async (id) => {
  try {
    const userRef = await firestore.collection("users").doc(id);
    const userData = await userRef.get();
    const user = userData.data();
    const newVacationDay = user.vacationday + 1;
    await userRef.update({
      vacationday: newVacationDay,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const plusUnpaidDay = async (id) => {
  try {
    const userRef = await firestore.collection("users").doc(id);
    const userData = await userRef.get();
    const user = userData.data();
    const newUnpaidDay = user.unpaid + 1;
    await userRef.update({
      unpaid: newUnpaidDay,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const plusParentalDay = async (id) => {
  try {
    const userRef = await firestore.collection("users").doc(id);
    const userData = await userRef.get();
    const user = userData.data();
    const newParentalDay = user.parentalLeave + 1;
    await userRef.update({
      parentalLeave: newParentalDay,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const plusOtherDay = async (id) => {
  try {
    const userRef = await firestore.collection("users").doc(id);
    const userData = await userRef.get();
    const user = userData.data();
    const newOtherDay = user.otherleave + 1;
    await userRef.update({
      otherleave: newOtherDay,
    });
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const getAlldayOffPending  = async () => {
  try {
    let list = [];
    const dayOffRef = await firestore.collection("dayOff").where("status","==","Pending");
    const dayOffData = await dayOffRef.get();
    await dayOffData.forEach(async (doc) => {
      list.push({...doc.data(), id: doc.id});
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
}

