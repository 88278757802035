import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { getAllTask } from "./../model/timeSheet";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import moment from "moment";
import { MdOutlineDeleteForever } from "react-icons/md";
import Select from "react-select";
import { getAllClients, getAllProjects, getAllTasks } from "../model/client";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
import { firestore } from "../firebase";
import { FaFileCsv } from "react-icons/fa";
import { AiTwotoneEdit } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";

export default function TimeSheetList() {
  const [items, setItems] = useState([]);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState({});

  const [client, setClient] = useState({
    name: "",
  });
  const [project, setProject] = useState({
    name: "",
  });
  const [taskTitle, setTaskTitle] = useState({
    name: "",
  });
  const [listFilter, setListFilter] = useState([
    {
      client: "",
      project: "",
      year: new Date().getFullYear().toString(),
    },
  ]);




  var regex = /[.,\s]/g;

  const [filterExport, setFilterExport] = useState({
    client: "",
    project: "",
    month: "",
    year: new Date().getFullYear().toString(),
  });

  const [dataExport, setDataExport] = useState([]);

  const headers = [
    { label: "ContactName", key: "client" },
    { label: "InvoiceDate", key: "invoiceDate" },
    { label: "DueDate", key: "dueDate" },
    { label: "Total", key: "total" },
    { label: "TaxTotal", key: "taxTotal" },
    { label: "InvoiceAmountDue", key: "amountDue" },
    { label: "Hours", key: "hours" },
    { label: "Minutes", key: "minutes" },
    { label: "Description", key: "description" },
    { label: "Quantity", key: "quantity" },
    { label: "UnitAmount", key: "unitAmount" },
    { label: "LineAmount", key: "lineAmount" },
    { label: "AccountCode", key: "accountCode" },
    { label: "TaxType", key: "taxType" },
    { label: "TaxAmount", key: "taxAmount" },
    { label: "Currency", key: "currency" },
    { label: "Type", key: "type" },
    { label: "Sent", key: "sent" },
    { label: "Status", key: "status" },
  ];

  const onChangeField = (field, value) => {
    setListFilter({ ...listFilter, [field]: value });
    setFilterExport({ ...filterExport, [field]: value });
  };

  const handleChange = (e) => {
    setListFilter({ ...listFilter, [e.target.name]: e.target.value });
    setFilterExport({ ...filterExport, [e.target.name]: e.target.value });
  };

  const handleClickExport = () => {
    setShowModalExport(true);
  };


  const month = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const data = await getAllTask();
      const clientData = await getAllClients();
      const projectData = await getAllProjects();
      const taskList = [];
      getAllTask().then((tasks) => {
        tasks.forEach((task) => {
          taskList.push({
            ...task,
            dateTime: moment(task.date).format("DD/MM/YYYY"),
            taskId: task.id,
          });
        });
      });
      setTasks(taskList);
      data.forEach((item) => {
        var someDate = new Date(Date.now());
        var someDate2 = new Date(Date.now());
        var add14Days = someDate.setDate(someDate.getDate() + 14);
        var add120Days = someDate2.setDate(someDate2.getDate() + 120);
        list.push({
          ...item,
          dateTime: moment(item.date).format("DD/MM/YYYY"),
          time:
            (item.hours === ""
              ? "00"
              : item.hours < 10
                ? "0" + item.hours
                : item.hours) +
            ":" +
            (item.minutes === ""
              ? "00"
              : item.minutes < 10
                ? "0" + item.minutes
                : item.minutes),
          country: clientData.find((x) => x.name === item.client).country,
          rate: clientData.find((x) => x.name === item.client).rate,
          invoiceDate: moment(new Date(Date.now())).format("DD/MM/YYYY"),
          dueDate:
            item.client === "Michelin ROH Co., Ltd."
              ? moment(new Date(add120Days)).format("DD/MM/YYYY")
              : moment(new Date(add14Days)).format("DD/MM/YYYY"),
          accountCode: "200",
          type: "Sales invoice",
          taskType: item.taskType,
          sent: item.sent,
          status: "Draft",
          unitHours: Number(
            ((Number(item.hours * 60) + Number(item.minutes)) / 60).toFixed(2)
          ),
          total:
            clientData.find((x) => x.name === item.client).country ===
              "Thailand"
              ? (
                Number(
                  (
                    Number(
                      (
                        (Number(item.hours * 60) + Number(item.minutes)) /
                        60
                      ).toFixed(2)
                    ) *
                    clientData.find((x) => x.name === item.client).rate *
                    (item.taskType === "general" ? 1 : 2)
                  ).toFixed(2)
                ) +
                Number(
                  (
                    Number(
                      (
                        (Number(item.hours * 60) + Number(item.minutes)) /
                        60
                      ).toFixed(2)
                    ) *
                    clientData.find((x) => x.name === item.client).rate *
                    0.07 *
                    (item.taskType === "general" ? 1 : 2)
                  ).toFixed(2)
                )
              ).toFixed(2)
              : Number(
                (
                  Number(
                    (
                      (Number(item.hours * 60) + Number(item.minutes)) /
                      60
                    ).toFixed(2)
                  ) *
                  clientData.find((x) => x.name === item.client).rate *
                  (item.taskType === "general" ? 1 : 2)
                ).toFixed(2)
              ).toFixed(2),
          taxTotal:
            clientData.find((x) => x.name === item.client).country ===
              "Thailand"
              ? (
                Number(
                  (
                    (Number(item.hours * 60) + Number(item.minutes)) /
                    60
                  ).toFixed(2)
                ) *
                clientData.find((x) => x.name === item.client).rate *
                0.07 *
                (item.taskType === "general" ? 1 : 2)
              ).toFixed(2)
              : 0,
          amountDue:
            clientData.find((x) => x.name === item.client).country ===
              "Thailand"
              ? (
                Number(
                  (
                    Number(
                      (
                        (Number(item.hours * 60) + Number(item.minutes)) /
                        60
                      ).toFixed(2)
                    ) *
                    clientData.find((x) => x.name === item.client).rate *
                    (item.taskType === "general" ? 1 : 2)
                  ).toFixed(2)
                ) +
                Number(
                  (
                    Number(
                      (
                        (Number(item.hours * 60) + Number(item.minutes)) /
                        60
                      ).toFixed(2)
                    ) *
                    clientData.find((x) => x.name === item.client).rate *
                    0.07 *
                    (item.taskType === "general" ? 1 : 2)
                  ).toFixed(2)
                )
              ).toFixed(2)
              : Number(
                (
                  Number(
                    (
                      (Number(item.hours * 60) + Number(item.minutes)) /
                      60
                    ).toFixed(2)
                  ) *
                  clientData.find((x) => x.name === item.client).rate *
                  (item.taskType === "general" ? 1 : 2)
                ).toFixed(2)
              ).toFixed(2),
          quantity: 1,
          taxAmount:
            clientData.find((x) => x.name === item.client).country ===
              "Thailand"
              ? (
                Number(
                  (
                    (Number(item.hours * 60) + Number(item.minutes)) /
                    60
                  ).toFixed(2)
                ) *
                clientData.find((x) => x.name === item.client).rate *
                0.07 *
                (item.taskType === "general" ? 1 : 2)
              ).toFixed(2)
              : 0,
          unitAmount: Number(
            (
              Number(
                ((Number(item.hours * 60) + Number(item.minutes)) / 60).toFixed(
                  2
                )
              ) *
              clientData.find((x) => x.name === item.client).rate *
              (item.taskType === "general" ? 1 : 2)
            ).toFixed(2)
          ),
          lineAmount: (
            Number(
              ((Number(item.hours * 60) + Number(item.minutes)) / 60).toFixed(2)
            ) *
            clientData.find((x) => x.name === item.client).rate *
            (item.taskType === "general" ? 1 : 2)
          ).toFixed(2),
          currency: clientData.find((x) => x.name === item.client).currency,
          taxType:
            clientData.find((x) => x.name === item.client).country ===
              "Thailand"
              ? "VAT (7%)"
              : "VAT (0%)",
          clientId: clientData.find((x) => x.name === item.client).id,
          additonalDescription: item.taskDescription,
          month: (new Date(item.date).getMonth() + 1).toString(),
          year: new Date(item.date).getFullYear().toString(),
          description: `See more details in ${window.location.origin}/tasksheet?client=${clientData.find((x) => x.name === item.client).id
            }&project=${projectData.find((x) => x.id === item.projectId).id
            }&month=${(new Date(item.date).getMonth() + 1).toString()}-${new Date(
              item.date
            )
              .getFullYear()
              .toString()} `,
        });
      });
      setItems(list);
    };
    const clientList = [];
    const getClientData = async () => {
      const clientData = await getAllClients();
      clientData.forEach((doc) => {
        clientList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          country: doc.country,
          rate: doc.rate,
        });
      });
      const sortByClientName = clientList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setClient(sortByClientName);
    };
    const projectList = [];
    const getProjectData = async () => {
      const projectData = await getAllProjects();
      projectData.forEach((doc) => {
        projectList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          client: doc.client,
        });
      });
      const sortByProjectName = projectList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setProject(sortByProjectName);
    };
    const taskTitleList = [];
    const getTaskTitleData = async () => {
      const taskTitleData = await getAllTasks();
      taskTitleData.forEach((doc) => {
        taskTitleList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          project: doc.project,
        });
      });
      const sortByTaskTitleName = taskTitleList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setTaskTitle(sortByTaskTitleName);
    };
    fetchData();
    getTaskTitleData();
    getProjectData();
    getClientData();
  }, []);


  const handleOpenModalEdit = async (id) => {
    setShowModalEdit(true);
    const TaskId = await firestore.collection("Tasks").doc(id).get();
    const selectedTaskk = tasks.filter((task) => task.id === TaskId.id);
    setSelectedTask(selectedTaskk[0]);
  };

  // const { SearchBar, ClearSearchButton } = Search;
  let nameFilter;
  let clientFilter;
  let projectFilter;
  let taskFilter;
  let taskDescFilter;
  let dateFilter;

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure, you want to delete this task?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          firestore.collection("Tasks").doc(id).delete();
          Swal.fire({
            title: "Deleted!",
            text: "Your task has been deleted.",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const button = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="flex gap-1">
        <div>
          <button
            className="btn btn-primary text-sm"
            onClick={() => {
              handleOpenModalEdit(row.id);
            }}
          >
            <div className="flex">
              <AiTwotoneEdit size={20} />
            </div>
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger text-sm"
            onClick={() => {
              handleDelete(row.id);
            }}
          >
            <div className="flex">
              <MdOutlineDeleteForever size={20} />
            </div>
          </button>
        </div>
      </div>
    );
  };

  const dayOffColumns = [
    {
      dataField: "dateTime",
      text: "Date",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          dateFilter = filter;
        },
      }),
    },
    {
      dataField: "client",
      text: "ContactName",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          clientFilter = filter;
        },
      }),
    },
    {
      dataField: "project",
      text: "Project",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          projectFilter = filter;
        },
      }),
    },
    {
      dataField: "taskTitle",
      text: "Task Title",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          taskFilter = filter;
        },
      }),
    },
    {
      dataField: "taskDescription",
      text: "Description",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          taskDescFilter = filter;
        },
      }),
    },
    {
      dataField: "invoiceDate",
      text: "InvoiceDate",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
      hidden: true,
    },
    {
      dataField: "dueDate",
      text: "DueDate",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
      hidden: true,
    },
    {
      dataField: "total",
      text: "Total",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
    },
    {
      dataField: "taxTotal",
      text: "TaxTotal",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
    },
    {
      dataField: "amountDue",
      text: "InvoiceAmountDue",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
    },
    {
      dataField: "quantity",
      text: "Quantity",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
      hidden: true,
    },
    {
      dataField: "unitAmount",
      text: "UnitAmount",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
      hidden: true,
    },
    {
      dataField: "lineAmount",
      text: "LineAmount",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
      hidden: true,
    },
    {
      dataField: "code",
      text: "AccountCode",
      hidden: true,
    },
    {
      dataField: "taxType",
      text: "TaxType",
      hidden: true,
    },
    {
      dataField: "type",
      text: "Type",
      hidden: true,
    },
    {
      dataField: "status",
      text: "Status",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      filter: textFilter({
        getFilter: (filter) => {
          nameFilter = filter;
        },
      }),
    },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      // filter: textFilter({
      //   getFilter: (filter) => {
      //     nameFilter = filter;
      //   },
      // }),
    },
    {
      dataField: "taskType",
      text: "Task Types",
      sort: true,
    },
    {
      dataField: "sent",
      text: "Sent",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      formatter: button,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const addClient = async (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to add "${value}" to client list ?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const clientRef = await firestore.collection("clients");
        clientRef.add({
          name: value,
        });
        Swal.fire({
          icon: "success",
          title: "Added!",
          text: `New client has been added to client list.`,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.reload();
            clientRef.onSnapshot((querySnapshot) => {
              const clientList = [];
              querySnapshot.forEach((doc) => {
                clientList.push({
                  value: doc.data().name,
                  label: doc.data().name,
                  id: doc.id,
                });
              });
              const sortByClientName = clientList.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              });
              setClient(sortByClientName);
            });
          }
        });
      }
    });
  };

  const addProject = async (value) => {
    if (items.client === "") {
      Swal.fire("Error!", "Please select client before adding project.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to add "${value}" to project list?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const projectRef = await firestore.collection("projects");
          projectRef.add({
            client: items.client,
            name: value,
          });
          Swal.fire({
            icon: "success",
            title: "Added!",
            text: `New project has been added to project list.`,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
              projectRef.onSnapshot((querySnapshot) => {
                const projectList = [];
                querySnapshot.forEach((doc) => {
                  projectList.push({
                    value: doc.data().name,
                    label: doc.data().name,
                    id: doc.id,
                    client: doc.data().client,
                  });
                });
                const sortByProjectName = projectList.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                });
                setProject(sortByProjectName);
              });
            }
          });
        } else {
          Swal.fire("Cancelled", "Your project has not been added.");
        }
      });
    }
  };

  const addTaskTitle = async (value) => {
    if (items.client === "" && items.project === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select client, project and task before adding task title."
      );
    } else if (items.project === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select project and task before adding task title."
      );
    } else if (items.client === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select client and task before adding task title."
      );
    } else if (items.client === "" && items.project === "") {
      Swal.fire(
        "Error!",
        "Please select client and project before adding task title."
      );
    } else if (items.task === "") {
      Swal.fire("Error!", "Please select task before adding task title.");
    } else if (items.project === "") {
      Swal.fire("Error!", "Please select project before adding task title.");
    } else if (items.client === "") {
      Swal.fire("Error!", "Please select client before adding task title.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to add "${value}" to task list?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const taskTitleRef = await firestore.collection("taskTitles");
          taskTitleRef.add({
            client: items.client,
            project: items.project,
            name: value,
          });
          Swal.fire({
            icon: "success",
            title: "Added!",
            text: `New task title has been added to task title list.`,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
              taskTitleRef.onSnapshot((querySnapshot) => {
                const taskTitleList = [];
                querySnapshot.forEach((doc) => {
                  taskTitleList.push({
                    value: doc.data().name,
                    label: doc.data().name,
                    id: doc.id,
                    client: doc.data().client,
                    project: doc.data().project,
                  });
                });
                const sortByTaskTitleName = taskTitleList.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                });
                setTaskTitle(sortByTaskTitleName);
              });
            }
          });
        } else {
          Swal.fire("Cancelled", "Your task title has not been added.");
        }
      });
    }
  };

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: items.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const handleEdit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to edit?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const taskRef = await firestore.collection("Tasks");
        taskRef.doc(selectedTask.id).update({
          ...selectedTask,
          sent: "",
        });
        Swal.fire("Edited!", "Your request has been edited.");
        setShowModalEdit(false);
      }
    });
  };


  return (
    <>
      {showModalConfirm ? (
        <>
          <div className="justify-center items-center h-screen   flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto  ">
              <div className="border-0 rounded-lg shadow-lg relative flex px-10 py-4 flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-center p-3 rounded-t">
                  <h2 className="text-2xl font-bold">Confirm</h2>
                </div>
                <div>Would you like to mark this task as sent?</div>
                <div className="flex items-center justify-center p-3 mt-3 text-md rounded-b gap-3 ">
                  <button
                    className="!bg-gray-500 text-white hover:!bg-gray-700 py-2 px-4 rounded-lg "
                    type="button"
                    onClick={() => setShowModalConfirm(false)}
                  >
                    Leave as-is
                  </button>
                  <button
                    className="!bg-green-600 text-white hover:!bg-green-700 py-2 px-4 rounded-lg "
                    type="button"
                    onClick={async () => {
                      const exportList = [];
                      const month = filterExport.month;
                      const year = filterExport.year;
                      const exportTask = items.filter(
                        (item) =>
                          item.month === month &&
                          item.year === year &&
                          item.client === filterExport.client &&
                          item.project === filterExport.project
                      );
                      exportTask.map((item) => {
                        return exportList.push(item.id);
                      });
                      const taskRef = await firestore.collection("Tasks");
                      exportList.map(async (item) => {
                        await taskRef.doc(item).update({
                          sent: "sent",
                        });
                      });
                      setShowModalConfirm(false);
                    }}
                  >
                    Mask as sent
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModalExport ? (
        <>
          <div className="justify-center items-center h-screen  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto  ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-center p-3 mt-2  rounded-t">
                  <h2 className="text-2xl font-bold">CSV data</h2>
                </div>
                <form className="w-full  p-1 px-3">
                  <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full mt-2 mx-4 px-3">
                      <div className="relative">
                        <span className="font-semibold ">Client Name</span>
                        <Select
                          className="basic-single max-w-[350px] mt-2 rounded-[10px] border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="client"
                          value={client.value}
                          options={client}
                          onChange={(e) =>
                            onChangeField("client", e ? e.value : null)
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <span className="font-semibold ">Client Project</span>
                        <Select
                          className="basic-single max-w-[350px] mt-2 rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={project.value}
                          options={Object.values(project).filter(
                            (item) => item.client === listFilter.client
                          )}
                          onChange={(e) =>
                            onChangeField("project", e ? e.value : null)
                          }
                        />
                      </div>
                      <div className="mt-3 ">
                        <div className="flex gap-4">
                          <div>
                            <span className="font-semibold">Month </span>
                            <Select
                              className="basic-single max-w-[350px] min-w-[200px] mt-2 rounded-[10px]   border-gray-300"
                              classNamePrefix="select"
                              isSearchable={true}
                              isClearable={true}
                              name="month"
                              value={month.value}
                              options={month}
                              onChange={(e) =>
                                onChangeField("month", e ? e.value : null)
                              }
                            />
                          </div>
                          <div className="flex flex-col">
                            <span className="font-semibold">Year </span>
                            <input
                              type="text"
                              className="basic-single max-w-[100px] mt-2 rounded-[5px]   border-gray-300"
                              name="year"
                              onChange={handleChange}
                              value={listFilter.year}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="flex mr-4 items-center text-sm justify-end p-3  rounded-b gap-2">
                  <button
                    className="!bg-red-400 text-white hover:!bg-red-700 p-2 rounded-lg "
                    type="button"
                    onClick={() => setShowModalExport(false)}
                  >
                    Close
                  </button>
                  <CSVLink
                    data={dataExport ? dataExport : []}
                    asyncOnClick={true}
                    headers={headers}
                    className="!bg-green-600 text-white hover:!bg-green-700 py-2 px-4 rounded-lg "
                    filename={
                      filterExport.client !== "" &&
                        filterExport.client !== null &&
                        filterExport.project !== "" &&
                        filterExport.project !== null &&
                        filterExport.month !== "" &&
                        filterExport.month !== null &&
                        filterExport.year !== "" &&
                        filterExport.year !== null
                        ? `${filterExport.client.replace(
                          regex,
                          ""
                        )}-${filterExport.project.replace(regex, "")}-${filterExport.month
                        }-${filterExport.year}`
                        : "export.csv"
                    }
                    onClick={async (event, done) => {
                      if (
                        listFilter.client &&
                        listFilter.project &&
                        listFilter.month &&
                        listFilter.year
                      ) {
                        var exportItem = items.filter(
                          (item) =>
                            item.client === listFilter.client &&
                            item.project === listFilter.project &&
                            item.month === listFilter.month &&
                            item.year === listFilter.year
                        );

                        if (exportItem.length > 0) {
                          var sumIist = exportItem.reduce((acc, item) => {
                            return {
                              client: item.client,
                              invoiceDate: item.invoiceDate,
                              dueDate: item.dueDate,
                              total: Number(
                                (
                                  Number(acc.total) + Number(item.total)
                                ).toFixed(2)
                              ),
                              taxTotal:
                                item.country === "Thailand"
                                  ? Number(
                                    (
                                      Number(acc.taxTotal) +
                                      Number(item.taxTotal)
                                    ).toFixed(2)
                                  )
                                  : 0,
                              amountDue: Number(
                                (
                                  Number(acc.amountDue) + Number(item.amountDue)
                                ).toFixed(2)
                              ),
                              hours: Number(acc.hours) + Number(item.hours),
                              minutes:
                                Number(acc.minutes) + Number(item.minutes),

                              quantity: 1,
                              unitAmount: Number(
                                (
                                  Number(acc.unitAmount) +
                                  Number(item.unitAmount)
                                ).toFixed(2)
                              ),
                              lineAmount: Number(
                                (
                                  Number(acc.lineAmount) +
                                  Number(item.lineAmount)
                                ).toFixed(2)
                              ),
                              accountCode: item.accountCode,
                              taxType: item.taxType,
                              taxAmount:
                                item.country === "Thailand"
                                  ? Number(
                                    (
                                      Number(acc.taxAmount) +
                                      Number(item.taxAmount)
                                    ).toFixed(2)
                                  )
                                  : 0,
                              currency: item.currency,
                              type: item.type,
                              sent: item.sent,
                              status: item.status,
                              description: item.description,
                            };
                          });
                          exportItem.push(sumIist);
                          exportItem = exportItem.map((item) => {
                            return {
                              ...item,
                            };
                          });
                          await setDataExport([sumIist]);

                        } else {
                          Swal.fire({
                            icon: "error",
                            text: "No data found",
                          });
                          done(false);
                        }
                      } else if (!listFilter.client) {
                        Swal.fire({
                          icon: "error",
                          text: "Please select client!",
                        });
                        done(false);
                      } else if (!listFilter.project) {
                        Swal.fire({
                          icon: "error",
                          text: "Please select project!",
                        });
                        done(false);
                      } else if (!listFilter.month) {
                        Swal.fire({
                          icon: "error",
                          text: "Please select month!",
                        });
                        done(false);
                      } else if (!listFilter.year) {
                        Swal.fire({
                          icon: "error",
                          text: "Please select year!",
                        });
                        done(false);
                      } else {
                        Swal.fire({
                          icon: "error",
                          text: "Please select all!",
                        });
                        done(false);
                      }
                      setShowModalExport(false);
                      if (exportItem.length > 0) {
                        setShowModalConfirm(true);
                      }

                    }}
                  >
                    Confirm
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModalEdit ? (
        <>
          <div className="justify-center mt-[10px] mb-[10px] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto  ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-2xl font-bold ml-2">Task Info</h2>
                </div>
                <div className="flex">
                  <form class="w-full  p-1 px-3">
                    <div class="flex flex-wrap mt-3 -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold  mt-2">
                          Client
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {selectedTask.client}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="client"
                          value={client.value}
                          options={client}
                          onChange={(e) =>
                            setSelectedTask({
                              ...selectedTask,
                              client: e.value,
                            })
                          }
                          onCreateOption={(value) => addClient(value)}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label className="block font-semibold  mt-2">
                          Project
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {selectedTask.project}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={project.value}
                          options={Object.values(project).filter(
                            (item) => item.client === selectedTask.client
                          )}
                          onChange={(e) =>
                            setSelectedTask({
                              ...selectedTask,
                              project: e ? e.value : null,
                            })
                          }
                          onCreateOption={(value) => addProject(value)}
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold  mt-2">
                          Task Title
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {selectedTask.taskTitle}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={taskTitle.value}
                          options={Object.values(taskTitle).filter(
                            (item) => item.project === selectedTask.project
                          )}
                          onChange={(e) =>
                            setSelectedTask({
                              ...selectedTask,
                              taskTitle: e ? e.value : null,
                            })
                          }
                          onCreateOption={(value) => addTaskTitle(value)}
                        />
                      </div>
                    </div>
                    <div class="flex  mt-3 -mx-3 mb-6">
                      <div class="w-full px-3 mb-6 md:mb-0">
                        <label className="block font-semibold mb-1 mt-1">
                          Description
                        </label>
                        <input
                          type="text"
                          name="taskDescription"
                          value={selectedTask.description}
                          className="border-gray-300 rounded-[5px] w-full"
                          onChange={(e) =>
                            setSelectedTask({
                              ...selectedTask,
                              taskDescription: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold mb-1 mt-1">
                          Hours
                        </label>
                        <input
                          className="border-gray-300 rounded-[5px] w-full"
                          type="text"
                          name="hours"
                          value={selectedTask.hours}
                          onChange={(e) =>
                            setSelectedTask({
                              ...selectedTask,
                              hours: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label className="block font-semibold mb-1 mt-1">
                          Minutes
                        </label>
                        <input
                          className="border-gray-300 rounded-[5px] w-full"
                          type="text"
                          name="minutes"
                          value={selectedTask.minutes}
                          onChange={(e) =>
                            setSelectedTask({
                              ...selectedTask,
                              minutes: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="w-full mt-4 mb-6 md:mb-0 px-3">
                        <label
                          className="block font-semibold mb-1 mt-1"
                          for="grid-martial-status"
                        >
                          Task  Type
                        </label>
                        <div className="relative">
                          <select
                            className="border-gray-300 rounded-[5px] w-full"
                            id="grid-department"
                            name="taskType"
                            onChange={(e) =>
                              setSelectedTask({
                                ...selectedTask,
                                taskType: e.target.value,
                              })
                            }
                            value={selectedTask.taskType || ""}
                          >
                            <option>general</option>
                            <option>emergency</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 !bg-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalEdit(false)}
                  >
                    Close
                  </button>
                  <button
                    className="!bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleEdit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="mb-10 mt-3 mx-10">
        <ToolkitProvider
          keyField="id"
          data={items}
          columns={dayOffColumns}
          exportCSV={{
            onlyExportFiltered: true,
            exportAll: false,
          }}
          columnToggle
        >
          {(props) => (
            <div>
              <div className="mt-10 mb-10 flex gap-4">
                <div className="text-3xl font-bold ">Task List</div>
                <div className="gap-2 flex">
                  <div>
                    <div
                      onClick={handleClickExport}
                      className="!bg-green-600 text-white p-2 rounded-[5px] hover:!bg-green-700 cursor-pointer"
                    >
                      <div className="flex gap-2 text-white">
                        <div>
                          <FaFileCsv
                            size={20}
                            className="mt-[3px]"
                            color="white"
                          />
                        </div>
                        Export CSV
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                condensed
                classes="mt-3 "
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}
