import { AuthContext } from "../context/AuthContext";
import { useState, useEffect, useContext } from "react";
import { getUserById } from "../model/user";
import { FaRegUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { firestore } from "../firebase";
import { getAllClients, getAllProjects, getAllTasks } from "../model/client";
import CreatableSelect from "react-select/creatable";
import Loading from "../components/loading";

export default function Timesheet() {
  //   const [enabled, setEnabled] = useState(true);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    startDate: "",
    endDate: "",
    reason: "",
    note: "",
    uid: "",
  });
  const [items, setItems] = useState({
    name: "",
    email: "",
    date: "",
    client: "",
    project: "",
    taskTitle: "",
    taskDescription: "",
    hours: "",
    minutes: "",
    taskType: "general",
  });
  const [client, setClient] = useState({
    name: "",
  });
  const [project, setProject] = useState({
    name: "",
  });
  const [taskTitle, setTaskTitle] = useState({
    name: "",
  });
  const [loading, setLoading] = useState(false);

  const onChangeField = (field, value) => {
    setItems({ ...items, [field]: value });
  };

  useEffect(() => {
    setLoading(true);
    const getUserData = async () => {
      const userData = await getUserById(currentUser.uid);
      setUser(userData);
      setData({
        ...data,
        name: userData.firstName + " " + userData.lastName,
        email: userData.email,
        uid: userData.id,
      });
      setItems({
        ...items,
        name: userData.firstName + " " + userData.lastName,
        email: userData.email,
      });
    };
    const clientList = [];
    const getClientData = async () => {
      const clientData = await getAllClients();
      clientData.forEach((doc) => {
        clientList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          country: doc.country,
          rate: doc.rate,
        });
      });
      const sortByClientName = clientList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setClient(sortByClientName);
    };
    const projectList = [];
    const getProjectData = async () => {
      const projectData = await getAllProjects();
      projectData.forEach((doc) => {
        projectList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          client: doc.client,
        });
      });
      const sortByProjectName = projectList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setProject(sortByProjectName);
      setLoading(false);
    };
    const taskTitleList = [];
    const getTaskTitleData = async () => {
      const taskTitleData = await getAllTasks();
      taskTitleData.forEach((doc) => {
        taskTitleList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          project: doc.project,
        });
      });
      const sortByTaskTitleName = taskTitleList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setTaskTitle(sortByTaskTitleName);
      setLoading(false);
    };
    if (currentUser) {
      getTaskTitleData();
      getProjectData();
      getClientData();
      getUserData();
    }
  }, []);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/user";
      }
    });
  };


  const handleSubmit = () => {
    if (
      items.hours === "" &&
      items.minutes === "" &&
      items.taskTitle === "" &&
      items.client === "" &&
      items.project === ""
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all the fields!",
      });
    } else if (items.client === "") {
      Swal.fire({
        icon: "error",
        text: "Please select client!",
      });
    } else if (items.project === "") {
      Swal.fire({
        icon: "error",
        text: "Please select project!",
      });
    } else if (items.taskTitle === "") {
      Swal.fire({
        icon: "error",
        text: "Please select task title!",
      });
    } else if (items.hours === "" && items.minutes === "") {
      Swal.fire({
        icon: "error",
        text: "Please fill hours or minutes!",
      });
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to submit?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const taskRef = await firestore.collection("Tasks");
          taskRef.add({
            ...items,
            name: user.firstName + " " + user.lastName,
            email: user.email,
            date: new Date().valueOf(),
            clientId: client.find((x) => x.label === items.client).id,
            projectId: project.find(
              (x) => x.client === items.client && x.label === items.project
            ).id,
            sent: "",
            userId: user.id,
          });
          Swal.fire("Submitted!", "Your request has been submitted.");
          setShowModalConfirm(false);
        }
      });
    }
  };

  const handleBeforeSubmit = () => {
    if (
      items.hours === "" &&
      items.minutes === "" &&
      items.taskTitle === "" &&
      items.client === "" &&
      items.project === ""
    ) {
      Swal.fire({
        icon: "error",
        text: "Please fill all the fields!",
      });
    } else if (items.client === "") {
      Swal.fire({
        icon: "error",
        text: "Please select client!",
      });
    } else if (items.project === "") {
      Swal.fire({
        icon: "error",
        text: "Please select project!",
      });
    } else if (items.taskTitle === "") {
      Swal.fire({
        icon: "error",
        text: "Please select task title!",
      });
    } else if (items.hours === "" && items.minutes === "") {
      Swal.fire({
        icon: "error",
        text: "Please fill hours or minutes!",
      });
    } else {
      setShowModalConfirm(true);
    }
  };

  const addClient = async (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to add "${value}" to client list ?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const clientRef = await firestore.collection("clients");
        clientRef.add({
          name: value,
        });
        Swal.fire({
          icon: "success",
          title: "Added!",
          text: `New client has been added to client list.`,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.reload();
            clientRef.onSnapshot((querySnapshot) => {
              const clientList = [];
              querySnapshot.forEach((doc) => {
                clientList.push({
                  value: doc.data().name,
                  label: doc.data().name,
                  id: doc.id,
                });
              });
              const sortByClientName = clientList.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              });
              setClient(sortByClientName);
            });
          }
        });
      }
    });
  };

  const addProject = async (value) => {
    if (items.client === "") {
      Swal.fire("Error!", "Please select client before adding project.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to add "${value}" to project list?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const projectRef = await firestore.collection("projects");
          projectRef.add({
            client: items.client,
            name: value,
          });
          Swal.fire({
            icon: "success",
            title: "Added!",
            text: `New project has been added to project list.`,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
              projectRef.onSnapshot((querySnapshot) => {
                const projectList = [];
                querySnapshot.forEach((doc) => {
                  projectList.push({
                    value: doc.data().name,
                    label: doc.data().name,
                    id: doc.id,
                    client: doc.data().client,
                  });
                });
                const sortByProjectName = projectList.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                });
                setProject(sortByProjectName);
              });
            }
          });
        } else {
          Swal.fire("Cancelled", "Your project has not been added.");
        }
      });
    }
  };

  const addTaskTitle = async (value) => {
    if (items.client === "" && items.project === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select client, project and task before adding task title."
      );
    } else if (items.project === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select project and task before adding task title."
      );
    } else if (items.client === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select client and task before adding task title."
      );
    } else if (items.client === "" && items.project === "") {
      Swal.fire(
        "Error!",
        "Please select client and project before adding task title."
      );
    } else if (items.task === "") {
      Swal.fire("Error!", "Please select task before adding task title.");
    } else if (items.project === "") {
      Swal.fire("Error!", "Please select project before adding task title.");
    } else if (items.client === "") {
      Swal.fire("Error!", "Please select client before adding task title.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to add "${value}" to task list?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const taskTitleRef = await firestore.collection("taskTitles");
          taskTitleRef.add({
            client: items.client,
            project: items.project,
            name: value,
          });
          Swal.fire({
            icon: "success",
            title: "Added!",
            text: `New task title has been added to task title list.`,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
              taskTitleRef.onSnapshot((querySnapshot) => {
                const taskTitleList = [];
                querySnapshot.forEach((doc) => {
                  taskTitleList.push({
                    value: doc.data().name,
                    label: doc.data().name,
                    id: doc.id,
                    client: doc.data().client,
                    project: doc.data().project,
                  });
                });
                const sortByTaskTitleName = taskTitleList.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                });
                setTaskTitle(sortByTaskTitleName);
              });
            }
          });
        } else {
          Swal.fire("Cancelled", "Your task title has not been added.");
        }
      });
    }
  };

  return (
    <>
      <div className="flex items-center justify-center max-w-screen min-h-screen bg-gradient-to-tl from-blue-200 to-blue-900 ">
        <div className="flex items-center justify-center">
          {showModalConfirm ? (
            <>
              <div className="justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative my-6 mx-auto  ">
                  <div className="border-0 rounded-lg shadow-lg relative flex px-10 py-4 flex-col w-full bg-white outline-none focus:outline-none">
                    <div className="flex items-center justify-center p-3 rounded-t">
                      <h2 className="text-2xl font-bold">Confirm Task</h2>
                    </div>
                    <div className="flex gap-5 mt-2 text-lg bg-gray-100 border-2 border-gray-400 rounded-lg py-3 px-[30px] ">
                      <div>
                        <div className="mt-1">
                          <label className="block font-semibold mb-1 mt-1">
                            Client:
                          </label>
                        </div>
                        <div className="mt-2 flex ">
                          <label className="block font-semibold mb-1 mt-2">
                            Project:
                          </label>
                        </div>
                        <div className="mt-2 flex ">
                          <label className="block font-semibold mb-1 mt-2">
                            Task Title:
                          </label>
                        </div>
                        <div className="mt-2 flex ">
                          <label className="block font-semibold mb-1 mt-2">
                            Task Description:
                          </label>
                        </div>
                        <div className="mt-2 flex ">
                          <label className="block font-semibold mb-1 mt-2">
                            Time:
                          </label>
                        </div>
                        <div className="mt-2 flex ">
                          <label className="block font-semibold mb-1 mt-2">
                            MInutes:
                          </label>
                        </div>
                      </div>
                      <div>
                        <div className="mt-1">
                          <label className="block font-semibold mb-1 mt-1">
                            {items.client}
                          </label>
                        </div>
                        <div className="mt-2 flex">
                          <label className="block font-semibold mb-1 mt-2">
                            {items.project}
                          </label>
                        </div>
                        <div className="mt-2 flex">
                          <label className="block font-semibold mb-1 mt-2">
                            {items.taskTitle}
                          </label>
                        </div>
                        <div className="mt-2 flex">
                          <label className="block font-semibold mb-1 mt-2">
                            {items.taskDescription !== ""
                              ? items.taskDescription
                              : "-"}
                          </label>
                        </div>
                        <div className="mt-2 flex">
                          <label className="block font-semibold mb-1 mt-2">
                            {items.hours} Hours{" "}
                            {items.minutes !== ""
                              ? items.minutes + " Minutes"
                              : ""}
                          </label>
                        </div>
                        <div className="mt-2 flex">
                          <label className="block font-semibold mb-1 mt-2">
                            {items.taskType}
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center p-3 mt-3 text-md rounded-b gap-3 ">
                      <button
                        className="!bg-red-500 text-white hover:!bg-red-700 py-2 px-4 rounded-lg "
                        type="button"
                        onClick={() => setShowModalConfirm(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className="!bg-green-600 text-white hover:!bg-green-700 py-2 px-4 rounded-lg "
                        type="button"
                        onClick={handleSubmit}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          {loading ? (
            <div>
              <Loading />
            </div>
          ) : (
            <div className="md:px-[40px] px-[20px] md:pt-[10px] pb-[20px]  text-left bg-white shadow-lg rounded-xl">
              <h3 className="text-2xl font-bold text-center mt-3">
                Client Task
              </h3>
              <form action="">
                <div className="mt-3">
                    <div className="flex gap-5 border-2 bg-gray-100 border-gray-300 rounded-[10px] md:p-6 s5m:p-3 ">
                    <div className="flex justify-between w-100 ">
                      <div className="flex gap-3 justify-center items-center">
                        <div className="mt-[6px]">
                            <FaRegUserCircle className="text-5xl text-gray-600 s5m:block hidden" />
                        </div>
                          <div className="font-semibold md:text-xl s5m:text-md text-sm  mb-3 mt-3 ">
                          <div>
                            {user.firstName} {user.lastName}{" "}
                          </div>
                          <div>{user.email}</div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <div
                              className="text-[10px] sm:text-[15px] text-center s5m:ml-3 s5m:mt-2 mt-1 cursor-pointer font-semibold text-white bg-green-600 hover:bg-white hover:!text-green-600 border-2 border-green-600 rounded-[100px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-1"
                            onClick={() =>
                              (window.location.href = "/user/tasklist")
                            }
                          >
                            Your Task Lists
                          </div>
                        </div>
                        <div>
                          <div
                              className="text-[10px] sm:text-[15px] text-center s5m:ml-3 mt-[6px] cursor-pointer font-semibold text-white bg-gray-600 hover:bg-white hover:!text-gray-600 border-2 border-gray-600 rounded-[100px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-1"
                            onClick={handleLogout}
                          >
                            Log out
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border-2 bg-gray-100 border-gray-300 p-4 rounded-[10px] mt-2">
                  <div className="sm:flex gap-5 ">
                    <div className="sm:block hidden">
                      <div className="mt-1">
                        <label className="block font-semibold mb-1 mt-1">
                          Client:
                        </label>
                      </div>
                      <div className="mt-4 flex ">
                        <label className="block font-semibold mb-1 mt-2">
                          Project:
                        </label>
                      </div>
                      <div className="mt-3 flex ">
                        <label className="block font-semibold mb-1 mt-2">
                          Task Title:
                        </label>
                      </div>
                      <div className="mt-4 flex ">
                        <label className="block font-semibold mb-1 mt-2">
                          Task Description:
                        </label>
                      </div>
                      <div className="mt-[20px] flex ">
                        <label className="block font-semibold mb-1 mt-2">
                          Time:
                        </label>
                      </div>
                      <div className="mt-[20px] flex ">
                        <label className="block font-semibold mb-1 mt-2">
                          Task Type:
                        </label>
                      </div>
                    </div>
                    <div>
                        <label className="sm:hidden block font-semibold mb-1 mt-1">
                          Client:
                        </label>
                      <div className="relative">
                        {client.length > 0 && (
                          <CreatableSelect
                            className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                            classNamePrefix="select"
                            isSearchable={true}
                            isClearable={true}
                            name="client"
                            value={client.value}
                            options={client}
                            onChange={(e) =>
                              onChangeField("client", e ? e.value : null)
                            }
                            onCreateOption={(value) => addClient(value)}
                          />
                        )}
                      </div>
                        <label className="sm:hidden block font-semibold mb-1 mt-3">
                          Project:
                        </label>
                        <div className="mt-3 ">
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={project.value}
                          options={Object.values(project).filter(
                            (item) => item.client === items.client
                          )}
                          onChange={(e) =>
                            onChangeField("project", e ? e.value : null)
                          }
                          onCreateOption={(value) => addProject(value)}
                        />
                      </div>
                        <label className="sm:hidden block font-semibold mb-1 mt-3">
                          Task Title:
                        </label>
                        <div className="mt-3">
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={taskTitle.value}
                          options={Object.values(taskTitle).filter(
                            (item) => item.project === items.project
                          )}
                          onChange={(e) =>
                            onChangeField("taskTitle", e ? e.value : null)
                          }
                          onCreateOption={(value) => addTaskTitle(value)}
                        />
                      </div>
                        <label className="sm:hidden block font-semibold mb-1 mt-3">
                          Task Description:
                        </label>
                        <div className="sm:mt-4  max-w-[350px] ">
                        <input
                          className="min-w-[330px]  rounded-[5px] border-gray-300"
                          type="text"
                          name="taskDescription"
                          onChange={(e) =>
                            onChangeField("taskDescription", e.target.value)
                          }
                        />
                      </div>
                        <label className="sm:hidden block font-semibold mb-1 mt-3">
                          Time:
                        </label>
                        <div className="mt-3  flex gap-3 max-w-[350px]  ">
                        <div className="flex">
                          <input
                            type="number"
                            name="hours"
                            className="border-gray-300 h-10 max-w-[100px] rounded-[5px] "
                            onChange={(e) =>
                              onChangeField("hours", e.target.value)
                            }
                          />
                          <span className="ml-3 mt-2 font-medium ">Hour</span>
                        </div>
                        <div className="flex">
                          <input
                            type="number"
                            name="minutes"
                            className="border-gray-300 h-10 max-w-[100px]  rounded-[5px] "
                            onChange={(e) =>
                              onChangeField("minutes", e.target.value)
                            }
                          />
                          <span className="ml-3 mt-2 font-medium ">
                            minutes
                          </span>
                        </div>
                      </div>
                        <label className="sm:hidden block font-semibold mb-1 mt-3">
                          Task Type:
                        </label>
                      <div className="mt-[30px]  flex gap-3 max-w-[350px]  ">
                        <div className="flex items-center mr-4">
                          <input
                            defaultChecked
                            id="inline-checked-radio"
                            type="radio"
                            value="general"
                            name="inline-radio-group"
                            className="w-4 h-4 text-blue-500 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
                            onChange={(e) =>
                              onChangeField("taskType", e.target.value)
                            }
                          />
                          <label
                            for="inline-checked-radio"
                            className="ml-2 text-sm font-medium "
                          >
                            General
                          </label>
                        </div>
                        <div className="flex items-center mr-4 ">
                          <input
                            id="inline-radio"
                            type="radio"
                            value="emergency"
                            name="inline-radio-group"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  focus:ring-2 "
                            onChange={(e) =>
                              onChangeField("taskType", e.target.value)
                            }
                          />
                          <label
                            for="inline-radio"
                            className="ml-2 text-sm font-medium"
                          >
                            Emergency
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="px-6 font-semibold py-3 mt-4 text-white mb-2 bg-blue-600 text-center rounded-[100px] hover:bg-blue-700 w-100 cursor-pointer"
                      onClick={handleBeforeSubmit}
                    >
                      Submit
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
