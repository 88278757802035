import { firestore } from "./../firebase";

export const getAllCheckin = async () => {
  try {
    let list = [];
    const checkinRef = await firestore.collection("checkIn").orderBy("date","desc");
    const checkinData = await checkinRef.get();
    await checkinData.forEach(async (doc) => {
        list.push({...doc.data(), id: doc.id});
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
};

