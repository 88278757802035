import { useEffect, useState } from "react";
import { getUserById } from "../model/user";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { firestore } from "../firebase";
import Swal from "sweetalert2";
import Loading from "../components/loading";
import { getAuth, updatePassword } from "firebase/auth";

export default function UserProfile() {
    const [user, setUser] = useState({});
    const { currentUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const auth = getAuth();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            const user = await getUserById(currentUser.uid);
            setUser(user);
            setLoading(false);
        };
        fetchData();
    }, []);


    const onChangeEdit = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const onSubmitEdit = async (e) => {
        e.preventDefault();
        await firestore.collection("users").doc(currentUser.uid).update({
            ...user,
            hireDateValue: new Date(user.hireDate).valueOf(),
            birthDateValue: new Date(user.birthDate).valueOf(),
        });
        updatePassword(auth.currentUser, user.password);
        Swal.fire({
            title: "Success",
            text: "User updated successfully",
            icon: "success",
            confirmButtonText: "OK",
        })
    };


    return (
        <>

            <div className="flex items-center justify-center max-w-screen min-h-screen bg-gradient-to-tl from-blue-200 to-blue-900 ">
                {loading ? (
                    <div>
                        <Loading />
                    </div>
                ) : (
                    <>
                        <div className="pt-10 pb-10">
                            <div>
                                <h1 className="text-3xl font-bold text-center border-2 bg-white border-gray-300 rounded-xl py-4 ">User Data</h1>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 mt-10">
                                <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit   ">
                                    <div>
                                        <div className="w-full">
                                            <div className="flex">
                                                <p className=" text-2xl  font-bold">Personal Details</p>
                                            </div>
                                            <div className="mt-4 ">
                                                <form className=" gap-4 ">
                                                    <div>
                                                        <div className="md:flex md:items-center mb-6 ">
                                                            <div className="md:w-1/3">
                                                                <label className="block   font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    First Name
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <input
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    type="text"
                                                                    value={user?.firstName}
                                                                    name="firstName"
                                                                    onChange={onChangeEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="md:flex md:items-center mb-6">
                                                            <div className="md:w-1/3">
                                                                <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    Last Name
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <input
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    type="text"
                                                                    value={user?.lastName}
                                                                    name="lastName"
                                                                    onChange={onChangeEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="md:flex md:items-center mb-6">
                                                            <div className="md:w-1/3">
                                                                <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    Email
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <input
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    type="text"
                                                                    value={user?.email}
                                                                    name="email"
                                                                    // onChange={onChangeEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="md:flex md:items-center mb-6">
                                                            <div className="md:w-1/3">
                                                                <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    Password
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <input
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    type="text"
                                                                    value={user?.password}
                                                                    name="password"
                                                                    onChange={onChangeEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="md:flex md:items-center mb-6">
                                                            <div className="md:w-1/3">
                                                                <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    Gender
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <select
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    id="grid-martial-status"
                                                                    name="gender"

                                                                    value={user?.gender}
                                                                    onChange={onChangeEdit}
                                                                >
                                                                    <option>Select gender </option>
                                                                    <option>Male</option>
                                                                    <option>Female</option>
                                                                    <option>Other</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="md:flex md:items-center mb-6">
                                                            <div className="md:w-1/3">
                                                                <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    Marital Status
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <select
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    id="grid-martial-status"
                                                                        name="maritalStatus"

                                                                    value={user?.maritalStatus}
                                                                    onChange={onChangeEdit}
                                                                >
                                                                    <option>Select status</option>
                                                                    <option>Single</option>
                                                                    <option>Married</option>
                                                                    <option>Divorced</option>
                                                                    <option>Widowed</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="md:flex md:items-center mb-6">
                                                            <div className="md:w-1/3">
                                                                <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                    Birth Date
                                                                </label>
                                                            </div>
                                                            <div className="md:w-2/3">
                                                                <input
                                                                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                    id="grid-birthdate"
                                                                    type="date"
                                                                    placeholder="01/01/1990"
                                                                    name="birthDate"

                                                                    value={user?.birthDate}
                                                                    onChange={onChangeEdit}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className="border-2 bg-white border-gray-300 rounded-xl p-6 h-fit  ">
                                        <div >
                                            <div className="w-full">
                                                <div className="">
                                                    <p className=" text-2xl  font-bold">Job details</p>
                                                </div>
                                                <div className="mt-4 ">
                                                    <form className=" gap-4 ">
                                                        <div>
                                                            <div className="md:flex md:items-center mb-6">
                                                                <div className="md:w-1/3">
                                                                    <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                        Start Date
                                                                    </label>
                                                                </div>
                                                                <div className="md:w-2/3">
                                                                    <input
                                                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                        id="grid-birthdate"
                                                                        type="date"
                                                                        placeholder="01/01/1990"
                                                                        name="hireDate"
                                                                        value={user?.hireDate}
                                                                        onChange={onChangeEdit}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="md:flex md:items-center mb-6">
                                                                <div className="md:w-1/3">
                                                                    <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                        Position
                                                                    </label>
                                                                </div>
                                                                <div className="md:w-2/3">
                                                                    <select
                                                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                        id="grid-martial-status"
                                                                        name="position"
                                                                        value={user?.position}
                                                                        onChange={onChangeEdit}
                                                                    >
                                                                        <option>Select Position</option>
                                                                        <option>Developer</option>
                                                                        <option>Accounting</option>
                                                                        <option>Marketing</option>
                                                                        <option>Photograph</option>
                                                                        <option>Designer</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="md:flex md:items-center mb-6">
                                                                <div className="md:w-1/3">
                                                                    <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                        Department
                                                                    </label>
                                                                </div>
                                                                <div className="md:w-2/3">
                                                                    <select
                                                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                        id="grid-martial-status"
                                                                        name="department"
                                                                        value={user?.department}
                                                                        onChange={onChangeEdit}
                                                                    >
                                                                        <option>Select Department</option>
                                                                        <option>Development</option>
                                                                        <option>Accounting</option>
                                                                        <option>Marketing</option>
                                                                        <option>Photograph</option>
                                                                        <option>Designer</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit mt-3  ">
                                        <div>
                                            <div className="w-full">
                                                <div className="flex">
                                                    <p className=" text-2xl  font-bold">Contact</p>
                                                </div>
                                                <div className="mt-4  ">
                                                    <form className=" gap-4 ">
                                                        <div>
                                                            <div className="md:flex md:items-center mb-6">
                                                                <div className="md:w-1/3">
                                                                    <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                        Current Address
                                                                    </label>
                                                                </div>
                                                                <div className="md:w-2/3">
                                                                    <input
                                                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                        id="grid-birthdate"
                                                                        type="text"
                                                                        name="address"
                                                                        value={user?.address}
                                                                        onChange={onChangeEdit}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="md:flex md:items-center mb-6">
                                                                <div className="md:w-1/3">
                                                                    <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                        Phone
                                                                    </label>
                                                                </div>
                                                                <div className="md:w-2/3">
                                                                    <input
                                                                        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                        id="grid-birthdate"
                                                                        type="text"
                                                                        name="phone"
                                                                        value={user?.phone}
                                                                        onChange={onChangeEdit}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" border-2 bg-white border-gray-300 rounded-xl p-6 h-fit mt-10  pr-10">
                                <div>
                                    <div className="w-full">
                                        <div className="flex">
                                            <p className=" text-2xl font-bold">Emergency Contact</p>
                                        </div>
                                        <div className="mt-4  flex gap-10  ">
                                            <form className=" gap-4 ">
                                                <div>
                                                    <div className="md:flex md:items-center mb-6">
                                                        <div className="md:w-1/3">
                                                            <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                First Name
                                                            </label>
                                                        </div>
                                                        <div className="md:w-2/3">
                                                            <input
                                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                id="grid-birthdate"
                                                                type="text"
                                                                name="EmergencyFirstName"
                                                                value={user?.EmergencyFirstName}
                                                                onChange={onChangeEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="md:flex md:items-center mb-6">
                                                        <div className="md:w-1/3">
                                                            <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                Relationship to User
                                                            </label>
                                                        </div>
                                                        <div className="md:w-2/3">
                                                            <input
                                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                id="grid-birthdate"
                                                                type="text"
                                                                name="EmergencyRole"
                                                                value={user?.EmergencyRole}
                                                                onChange={onChangeEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <form className=" gap-4 ">
                                                <div>
                                                    <div className="md:flex md:items-center mb-6">
                                                        <div className="md:w-1/3">
                                                            <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                Last Name
                                                            </label>
                                                        </div>
                                                        <div className="md:w-2/3">
                                                            <input
                                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                id="grid-birthdate"
                                                                type="text"
                                                                name="EmergencyLastName"
                                                                value={user?.EmergencyLastName}
                                                                onChange={onChangeEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="md:flex md:items-center mb-6">
                                                        <div className="md:w-1/3">
                                                            <label className="block  font-bold md:text-right mb-1 md:mb-0 pr-4">
                                                                Phone
                                                            </label>
                                                        </div>
                                                        <div className="md:w-2/3">
                                                            <input
                                                                className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-600"
                                                                id="grid-birthdate"
                                                                type="text"
                                                                name="EmergencyPhone"
                                                                value={user?.EmergencyPhone}
                                                                onChange={onChangeEdit}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="items-center justify-center flex mt-5 mb-4 gap-4">
                                <div>
                                    <button className="px-5 py-2 rounded-xl font-bold bg-green-800 border-2 border-gray-300 hover:bg-green-900 text-white"
                                        onClick={onSubmitEdit}
                                    >
                                        Save Change
                                    </button>
                                </div>
                            </div>
                        </div></>
                )}
            </div></>
    );
}
