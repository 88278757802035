import React, { useEffect } from "react";
import { useState } from "react";
import { firestore, auth } from "../firebase";
import Swal from "sweetalert2";
import { getUserById } from "../model/user";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";
import { AiTwotoneEdit } from "react-icons/ai";
import { MdOutlineDeleteForever } from "react-icons/md";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export default function Employees() {
  const { currentUser } = useContext(AuthContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [currUser, setCurrUser] = useState(null);


  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    birthDate: "",
    hireDate: "",
    position: "",
    department: "",
    gender: "",
    maritalStatus: "",
    admin: false,
    id: "",
    sickday: 0,
    vacationday: 0,
    parentalLeave: 0,
    unpaid: 0,
    otherleave: 0,
    active: true,
  });

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeRole = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value === "User" ? false : true });
  };



  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const getUsersRef = firestore.collection("users");
        const snapshot = await getUsersRef.get();
        snapshot.forEach((doc) => {
          list.push({
            ...doc.data(),
            id: doc.id,
            fullname: doc.data().firstName + " " + doc.data().lastName,
            BirthDate: moment(doc.data().birthDateValue).format("MM/DD/YYYY"),
            HireDate: moment(doc.data().hireDateValue).format("MM/DD/YYYY"),
          });
        });
        // console.log(list);
        // setItems(list.filter((item) => item.admin === false));
        setItems(list.filter((item) => item.active === true));
        const user = await getUserById(currentUser.uid);
        setCurrUser(user);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    if (items.length === 0) {
      fetchData();
    }
  }, []);

  const handleEdit = async (e) => {
    e.preventDefault();
    // setShowModalEdit(true);
    try {
      const editUserRef = firestore.collection("users").doc(user.id);
      await editUserRef.update({
        ...user,
        hireDateValue: new Date(user.hireDate).valueOf(),
        birthDateValue: new Date(user.birthDate).valueOf(),
        sickday: parseInt(user.sickday),
        vacationday: parseInt(user.vacationday),
        parentalLeave: parseInt(user.parentalLeave),
        unpaid: parseInt(user.unpaid),
        otherleave: parseInt(user.otherleave),
      });
      Swal.fire({
        title: "Success",
        text: "User updated successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onClickEdit = async (id) => {
    const user = await getUserById(id);
    setUser({ ...user, id: id });
    console.log(user);
    setShowModalEdit(true);
  };


  const handleDelete = async (id, uid) => {
    try {
      Swal.fire({
        icon: "warning",
        text: "Are you sure, you want to delete this user?",
        showCancelButton: true,
        backdrop: "rgba(120,120,120,0.5)",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const changeActiveUserRef = firestore.collection("users").doc(id);
          await changeActiveUserRef.update({
            active: false,
          });
          setItems(items.filter((item) => item.id !== id));
          Swal.fire("Deleted!", "User has been deleted.", "success");
        } else {
          Swal.fire("Cancelled", "User is safe :)", "error");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const onRowEvents = {
  //   onClick: (e, row, rowIndex) => {
  //     window.location.href = `employee?id=${row.id}`;
  //   },
  // };



  const { SearchBar, ClearSearchButton } = Search;

  const button = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="flex gap-1">
        <button
          className="btn btn-primary  text-sm"
          onClick={() => {
            onClickEdit(row.id);
          }}
        >
          <div className="flex ">
            <AiTwotoneEdit size={20} />
          </div>
        </button>
        <button
          className="btn btn-danger text-sm"
          onClick={() => {
            handleDelete(row.id, row.uid);
          }}
        >
          <div className="flex ">
            <MdOutlineDeleteForever size={20} />
          </div>
        </button>
      </div>
    );
  };

  const UserColumns = [
    {
      dataField: "fullname",
      text: "Name",
      sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          window.location.href = `employee?id=${row.id}`;
        }
      },
      style: {
        cursor: "pointer",
      }
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "department",
      text: "Department",
      sort: true,
    },
    {
      dataField: "position",
      text: "Position",
      sort: true,
    },
    {
      dataField: "HireDate",
      text: "Start Date",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      formatter: button,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: items.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };


  return (
    <>
      {showModalEdit ? (
        <>
          <div className="justify-center mt-[10px] mb-[10px] flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-[100px] ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-fit bg-white outline-none focus:outline-none">
                <div className="flex  p-3 text-center items-center justify-center  rounded-t">
                  <h2 className="text-2xl font-bold ml-2 ">User data</h2>
                </div>
                <div className="flex ">
                  <form className="w-full  p-1 px-5">
                    <span className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2 ml-1">
                      Personal Details
                    </span>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          First Name
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-first-name"
                          type="text"
                          placeholder="Jane"
                          name="firstName"
                          onChange={handleChange}
                          value={user.firstName}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Last Name
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Doe"
                          name="lastName"
                          onChange={handleChange}
                          value={user.lastName}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                      {/* <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-password"
                        >
                          Email
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-username"
                          type="text"
                          placeholder="John"
                          name="email"
                          onChange={handleChange}
                          value={user.email}
                        />
                      </div> */}
                      {/* <div className="w-full md:w-1/2 px-3">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-password"
                          >
                            Password
                          </label>
                          <input
                            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-password"
                            type="text"
                            placeholder="******************"
                            name="password"
                            onChange={handleChange}
                            value={user.password}
                          />
                        </div> */}
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-city"
                        >
                          Birth Date
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-birthdate"
                          type="date"
                          placeholder="01/01/1990"
                          name="birthDate"
                          onChange={handleChange}
                          value={user.birthDate}
                        />
                      </div>
                      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-martial-status"
                        >
                          Marital Status
                        </label>
                        <div className="relative">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-martial-status"
                            name="maritalStatus"
                            onChange={handleChange}
                            value={user.maritalStatus}
                          >
                            <option>Select status</option>
                            <option>Single</option>
                            <option>Married</option>
                            <option>Divorced</option>
                            <option>Widowed</option>
                          </select>
                        </div>
                      </div>
                      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-gender"
                        >
                          Gender
                        </label>
                        <div className="relative">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-martial-status"
                            name="gender"
                            onChange={handleChange}
                            value={user.gender}
                          >
                            <option>Select gender </option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <span className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2 mt-[50px] ml-1">
                      Contact
                    </span>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          current Address
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-email"
                          type="text"
                          placeholder="701 April road"
                          name="address"
                          onChange={handleChange}
                          value={user.address}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-phone"
                        >
                          Phone
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-phone"
                          type="text"
                          placeholder="0999999999"
                          name="phone"
                          onChange={handleChange}
                          value={user.phone}
                        />
                      </div>
                    </div>
                    <span className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2 mt-[50px] ml-1">
                      Job Details
                    </span>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-city"
                        >
                          start Date
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-birthdate"
                          type="date"
                          placeholder="01/01/1990"
                          name="hireDate"
                          onChange={handleChange}
                          value={user.hireDate}
                        />
                      </div>
                      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-martial-status"
                        >
                          Position
                        </label>
                        <div className="relative">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-martial-status"
                            name="position"
                            value={user.position}
                            onChange={handleChange}
                          >
                            <option>Select Position</option>
                            <option>Developer</option>
                            <option>Accounting</option>
                            <option>Marketing</option>
                            <option>Photograph</option>
                            <option>Designer</option>
                          </select>
                        </div>
                      </div>
                      <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-martial-status"
                        >
                          Department
                        </label>
                        <div className="relative">
                          <select
                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            id="grid-department"
                            name="department"
                            onChange={handleChange}
                            value={user.department}
                          >
                            <option>Select Department</option>
                            <option>Development</option>
                            <option>Accounting</option>
                            <option>Marketing</option>
                            <option>Photograph</option>
                            <option>Designer</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <span className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-2 mt-[50px] ml-1">
                      Emergency Contact
                    </span>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          First Name
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-first-name"
                          type="text"
                          placeholder="Jane"
                          name="EmergencyFirstName"
                          onChange={handleChange}
                          value={user.EmergencyFirstName}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Last Name
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-last-name"
                          type="text"
                          placeholder="Doe"
                          name="EmergencyLastName"
                          onChange={handleChange}
                          value={user.EmergencyLastName}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-2">
                      <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-password"
                        >
                          Relationship to User
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-username"
                          type="text"
                          placeholder="Parent"
                          name="EmergencyRole"
                          onChange={handleChange}
                          value={user.EmergencyRole}
                        />
                      </div>
                      <div className="w-full md:w-1/2 px-3">
                        <label
                          className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-password"
                        >
                          Phone
                        </label>
                        <input
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-password"
                          type="text"
                          placeholder="01111111"
                          name="EmergencyPhone"
                          onChange={handleChange}
                          value={user.EmergencyPhone}
                        />
                      </div>
                    </div>
                    {currUser.specificRole === "Super Admin" && (
                      <>
                    <span className="block uppercase tracking-wide text-gray-700 text-lg font-bold mb-3 mt-[40px] ">
                      Role
                    </span>
                        <div className="w-full md:w-1/3  mb-6 md:mb-0">
                          <label
                            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            for="grid-martial-status"
                          >
                            Role
                          </label>
                          <div className="relative">
                            <select
                              className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              id="grid-department"
                              name="admin"
                              onChange={handleChangeRole}
                              value={user.admin===true?"Admin":"User"}
                            >
                              <option>Select Role</option>
                              <option>User</option>
                              <option>Admin</option>
                            </select>
                          </div>
                        </div>
                    </>)} 
                  </form>
                </div>
                <div className="flex items-center gap-3 justify-center p-2 mt-2  rounded-b">
                  <button
                    className="!bg-gray-400 text-white hover:!bg-gray-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalEdit(false)}
                  >
                    Close
                  </button>
                  <button
                    className="!bg-emerald-500 text-white hover:!bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleEdit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="mt-10 mx-10 flex justify-between">
        <div className="text-3xl font-bold">Users</div>
      </div>
      <div className="mt-10 mx-10">
        <ToolkitProvider
          keyField="id"
          data={items}
          columns={UserColumns}
          search
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} className="!rounded-[5px] " />
              <ClearSearchButton
                {...props.searchProps}
                className="btn border ml-3 p-2 hover:bg-gray-600 hover:text-white text-sm "
              />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                condensed
                classes="mt-3 "
              // rowEvents={onRowEvents}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}

