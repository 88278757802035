import { useState, useEffect } from "react";
import moment from "moment";
import { firestore } from "../firebase";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import {
  add,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  isEqual,
  isSameMonth,
  isToday,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import { FaBirthdayCake } from "react-icons/fa";
import { GoBell } from "react-icons/go";
import { getAllDayOff } from "../model/dayOff";
import { getAllCheckin } from "../model/checkIn";
import { FiUsers } from "react-icons/fi";
import { MdOutlineCancel, MdPendingActions, MdCancel } from "react-icons/md";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const [meetings, setMeeting] = useState([]);
  const [dayOff, setDayOff] = useState([]);
  const [allDayOff, setAllDayOff] = useState([]);
  const [checkIn, setCheckIn] = useState([]);
  const [pendingDayOff, setPendingDayOff] = useState([]);
  const [time, setTime] = useState(moment().format("LTS"));
  let today = startOfToday();
  let [selectedDay, setSelectedDay] = useState(today);
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());

  setInterval(function () {
    var todaymoment = moment().format("LTS");
    setTime(todaymoment);
  }, 1000);

  let days = eachDayOfInterval({
    start: firstDayCurrentMonth,
    end: endOfMonth(firstDayCurrentMonth),
  });

  function previousMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
  }

  const isBirthday = (birthdate, selectedDay) => {
    let birthdateDay = parseISO(birthdate);
    return (
      birthdateDay.getDate() === selectedDay.getDate() &&
      birthdateDay.getMonth() === selectedDay.getMonth()
    );
  };

  let birthdays = meetings.filter((meeting) =>
    isBirthday(meeting.BirthDate, selectedDay)
  );

  const isAniversary = (aniversary, selectedDay) => {
    let aniversaryDay = parseISO(aniversary);
    return (
      aniversaryDay.getDate() === selectedDay.getDate() &&
      aniversaryDay.getMonth() === selectedDay.getMonth()
    );
  };

  let aniversaries = meetings.filter((meeting) =>
    isAniversary(meeting.HireDate, selectedDay)
  );

  const isDayOff = (dayOff, selectedDay) => {
    let dayOffDay = parseISO(dayOff);
    return (
      dayOffDay.getDate() === selectedDay.getDate() &&
      dayOffDay.getMonth() === selectedDay.getMonth()
    );
  };

  let dayOffs = allDayOff.filter((dayOff) => isDayOff(dayOff.StartDate, selectedDay));


  // let selectedDayMeetings = meetings.filter((meeting) =>
  //   isBirthday(parseISO(meeting.BirthDate), selectedDay)
  // );

  // const findsameuserinlist = (list, user) => {
  //   let result = list.filter((item) => item.uid === user.uid);
  //   return result;
  // };

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const getUsersRef = firestore.collection("users");
        const snapshot = await getUsersRef.get();
        snapshot.forEach((doc) => {
          list.push({
            ...doc.data(),
            id: doc.id,
            fullname: doc.data().firstName + " " + doc.data().lastName,
            BirthDate: moment(doc.data().birthDateValue).format(
              "YYYY-MM-DDTHH:mm"
            ),
            HireDate: moment(doc.data().hireDateValue).format(
              "YYYY-MM-DDTHH:mm"
            ),
            role: doc.data().admin,
          });
        });
        setMeeting(list.filter((item) => item.active === true));

        const dayOffList = await getAllDayOff();
        const dayOffListApprovedtoday = dayOffList.filter((dayOff) => {
          return (
            dayOff.status === "Approved" &&
            moment(dayOff.startDate).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
          );
        });
        setDayOff(dayOffListApprovedtoday);


        const getAllDayOffRef = firestore.collection("dayOff");
        const snapshotAllDayOff = await getAllDayOffRef.get();
        let listAllDayOff = [];
        snapshotAllDayOff.forEach((doc) => {
          listAllDayOff.push({
            ...doc.data(),
            id: doc.id,
            StartDate: moment(doc.data().startDate).format(
              "YYYY-MM-DDTHH:mm"
            ),
            EndDate: moment(doc.data().endDate).format(
              "YYYY-MM-DDTHH:mm"
            ),
          });
        });
        setAllDayOff(listAllDayOff.filter((dayOff) => dayOff.status === "Approved"));

        setPendingDayOff(
          dayOffList.filter((dayOff) => {
            return dayOff.status === "Pending";
          })
        );
        const checkInList = await getAllCheckin();
        const checkInListtoday = checkInList.filter((checkIn) => {
          return (
            moment(checkIn.checkInTime).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
          );
        });
        setCheckIn(checkInListtoday);
      } catch (error) {
        console.log(error);
      }
    };

    if (meetings.length === 0) {
      fetchData();
    }
  }, []);


  return (
    <div className="pt-10 mx-10">
      <div className="container ">
        <div className="flex">
          <div className="font-bold text-4xl mb-5">Overview</div>
          <div className="ml-5 font-semibold mt-3">
            {moment().format("dddd, ll")}
            <span className="ml-2">{time}</span>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="flex border-2 border-gray-300 rounded-xl p-6  ">
            <div>
              <div className="flex">
                <FiUsers className="text-3xl text-blue-600" />
                <p className=" text-xl ml-3   font-semibold">Total Employees</p>
              </div>
              <p className="text-4xl pt-3 font-bold">{meetings.length}</p>
            </div>
          </div>
          <div className="flex border-2 border-gray-300 rounded-xl p-6 ">
            <div>
              <div>
                <div className="flex">
                  <MdOutlineCancel className="text-3xl text-red-600" />
                  <p className=" text-xl ml-3 font-semibold">Today's leave</p>
                </div>
              </div>
              <p className="text-4xl  pt-3 font-bold"> {dayOff.length} </p>
            </div>
          </div>
          <div className="flex border-2 border-gray-300 rounded-xl p-6 ">
            <div>
              <div>
                <div className="flex">
                  <MdPendingActions className="text-3xl text-yellow-600" />
                  <p className=" text-xl ml-3 font-semibold">Pending Leave Requests</p>
                </div>
              </div>
              <div className="flex">
                <p className="text-4xl pt-3 font-bold">
                  {pendingDayOff.length}
                </p>
                <div
                  className="mt-4 ml-4 text-md font-bold text-gray-400 hover:underline cursor-pointer"
                  onClick={() => (window.location.href = "/admin/shiftlist")}
                >
                  Check now
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex mb-5">
        <div className="ml-[13px] max-w-lg px-4  sm:px-7 md:max-w-4xl md:px-6 mt-4 border-2 rounded-xl border-gray-300 p-3">
          <div className="md:grid md:grid-cols-2 md:divide-x md:divide-gray-200">
            <div className="md:pr-14">
              <div className="flex items-center">
                <h2 className="flex-auto font-semibold text-gray-900">
                  {format(firstDayCurrentMonth, "MMMM yyyy")}
                </h2>
                <button
                  type="button"
                  onClick={previousMonth}
                  className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Previous month</span>
                  <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
                </button>
                <button
                  onClick={nextMonth}
                  type="button"
                  className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Next month</span>
                  <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
              <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
                <div>S</div>
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
              </div>
              <div className="grid grid-cols-7 mt-2 text-sm">
                {days.map((day, dayIdx) => (
                  <div
                    key={day.toString()}
                    className={classNames(
                      dayIdx === 0 && colStartClasses[getDay(day)],
                      "py-1.5"
                    )}
                  >
                    <button
                      type="button"
                      onClick={() => setSelectedDay(day)}
                      className={classNames(
                        isEqual(day, selectedDay) && "!text-white",
                        !isEqual(day, selectedDay) &&
                        isToday(day) &&
                        "!text-red-500",
                        !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        isSameMonth(day, firstDayCurrentMonth) &&
                        "!text-gray-900",
                        !isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        !isSameMonth(day, firstDayCurrentMonth) &&
                        "!text-gray-400",
                        isEqual(day, selectedDay) &&
                        isToday(day) &&
                        "!bg-red-500",
                        isEqual(day, selectedDay) &&
                        !isToday(day) &&
                        "!bg-gray-900",
                        !isEqual(day, selectedDay) && "!hover:bg-gray-200",
                        (isEqual(day, selectedDay) || isToday(day)) &&
                        "font-semibold",
                        "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
                      )}
                    >
                      <time dateTime={format(day, "yyyy-MM-dd")}>
                        {format(day, "d")}
                      </time>
                    </button>

                    <div className="w-1 h-1 mx-auto mt-1">
                      {((meetings.some(
                        (meeting) =>
                          isBirthday(meeting.BirthDate, day) ||
                          isAniversary(meeting.HireDate, day)

                      )) || (allDayOff.some(
                        (dayOff) =>
                          isDayOff(dayOff.StartDate, day)
                      ))) && (
                          <div className="w-1 h-1 rounded-full !bg-sky-500"></div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <section className="mt-12 md:mt-0 md:pl-14">
              <h2 className="font-semibold text-xl text-gray-900">
                Event on{" "}
                <time dateTime={format(selectedDay, "yyyy-MM-dd")}>
                  {format(selectedDay, "MMM dd, yyy")}
                </time>
              </h2>
              <ol className="mt-4 space-y-1 text-lg leading-6 text-gray-500 mb-5">
                {(birthdays.length > 0 || aniversaries.length > 0 || dayOffs.length != 0) ? (
                  // birthdays.map((meeting) => (
                  //   <Meeting meeting={meeting} key={meeting.id} />
                  // ))
                  <>
                    {birthdays.map((meeting) => (
                      <Meeting
                        meeting={meeting}
                        key={meeting.id}
                        selectedDay={selectedDay}
                      />
                    ))}
                    {aniversaries.map((meeting) => (
                      <Birthday
                        meeting={meeting}
                        key={meeting.id}
                        selectedDay={selectedDay}
                      />
                    ))}
                    {dayOffs.map((dayOff) => (
                      <DayOff
                        dayOff={dayOff}
                        key={dayOff.id}
                        selectedDay={selectedDay}
                      />
                    ))}
                  </>
                ) : (
                  <p>No event on today.</p>
                )}
              </ol>
            </section>
          </div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

function Meeting({ meeting }) {
  return (
    <li className="flex items-center px-4 py-3 space-x-4 group rounded-xl focus-within:bg-gray-100 bg-gray-100">
      <div>
        <FaBirthdayCake size={35} className="text-orange-400" />
      </div>
      <div className="flex-auto text-md">
        <p className="text-gray-900 ">
          {" "}
          <span className="font-semibold ">{meeting.fullname} </span>
          has birthday today.
        </p>
        <p className="text-gray-900">Wish them well!</p>
      </div>
    </li>
  );
}

function DayOff({ dayOff }) {
  return (
    <li className="flex mt-3 items-center px-4 py-3 space-x-4 group rounded-xl focus-within:bg-gray-100 bg-gray-100">
      <div>
        <MdCancel size={35} className="text-red-500" />
      </div>
      <div className="flex-auto text-md">
        <p className="text-gray-900 ">
          {" "}
          <span className="font-semibold ">{dayOff.name} </span>
          leaves today.
        </p>
      </div>
    </li>
  );
}

function Birthday({ meeting, selectedDay }) {
  // console.log(new Date(selectedDay).getFullYear() - new Date(meeting.HireDate).getFullYear());
  return (
    <li className="flex mt-3 items-center px-4 py-3 space-x-4 group rounded-xl focus-within:bg-gray-100 bg-gray-100">
      <div>
        <GoBell size={35} className="text-blue-400" />
      </div>
      <div className="flex-auto text-md">
        <p className="text-gray-900 ">
          {" "}
          <span className="font-semibold ">{meeting.fullname} </span>
          {new Date(selectedDay).getFullYear() -
            new Date(meeting.HireDate).getFullYear() ===
            0 ? (
            <span>has joined today.</span>
          ) : (
            <span>
              has joined{" "}
              {new Date(selectedDay).getFullYear() -
                new Date(meeting.HireDate).getFullYear()}{" "}
              {new Date(selectedDay).getFullYear() -
                new Date(meeting.HireDate).getFullYear() ===
                "1"
                ? "year"
                : "years"}{" "}
              ago.
            </span>
          )}
        </p>
        <p className="text-gray-900">
          {new Date(selectedDay).getFullYear() -
            new Date(meeting.HireDate).getFullYear() ===
            0
            ? "Welcome to the Marketing Bear!"
            : "Wish them well!"}
        </p>
      </div>
    </li>
  );
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
];
