import { React, useEffect, useState } from "react";
import axios from "axios";
import Loading from "../components/loading";
import { Link } from "react-router-dom";
import config from "../config";

export default function Message() {
  const [amount, setAmount] = useState("");
  const [failed, setFailed] = useState(true);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("amount");
    var d = url.searchParams.get("?customer");
    setAmount(c);
    axios
      .get(`${config.path_to_backend}check-status`)
      .then((res) => {
        const temp = res.data.data.map((item) => {
          return {
            customer: item.customer,
            amount: item.amount.toString(),
            status: item.status,
          };
        });

        if (c !== null && d !== null) {
          for (let i = 0; i < temp.length; i++) {
            if (
              temp[i].customer === d &&
              temp[i].amount === c &&
              temp[i].status === "successful"
            ) {
              setFailed(false);
            } else {
              setFailed(true);
            }
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="my-[80px] 2xl:mx-[600px]  xl:mx-[400px] lg:mx-[300px] md:mx-[200px] sm:mx-[100px] mx-[70px]">
        {loading ? (
          <Loading />
        ) : (
          <div className="bg-white py-10 px-[10px]  md:mx-auto rounded-[15px]">
            {failed === false ? (
              <svg
                viewBox="0 0 24 24"
                className="text-green-600 w-50 h-40 mx-auto my-6"
              >
                <path
                  fill="currentColor"
                  d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
                ></path>
              </svg>
            ) : (
              <img
                src="/icons8-cancel-144.png"
                alt="cancel"
                className="texr-center flex items-center m-auto"
              />
            )}

            <div className="text-center">
              <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
                {failed === true ? "Payment Failed!" : "Payment Done!"}
              </h3>
              <p className="text-gray-900 my-2 mt-5 text-[17px]">
                {failed === true
                  ? ""
                  : "The MB Accounting Team will send you your receipt in one business day."}
              </p>
              <p className="text-gray-600 my-2">
                {failed === true
                  ? ""
                  : `Amount : ${Number(amount / 100).toFixed(2)} Baht`}
              </p>
            </div>
            {failed === true ? (
              <div className="text-center mt-5">
                <Link to="/invoice">
                  <button
                    type="button"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                  >
                    Try Again
                  </button>
                </Link>
              </div>
            ) : null}
          </div>
        )}
      </div>
      )
    </div>
  );
}
