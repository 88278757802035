import { firestore } from "./../firebase";

export const getAllTask = async () => {
  try {
    let list = [];
    const taskRef = await firestore.collection("Tasks").orderBy("date", "desc");
    const taskData = await taskRef.get();
    await taskData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllRequests = async () => {
  try {
    let list = [];
    const requestRef = await firestore
      .collection("requests")
      .orderBy("date", "desc");
    const requestData = await requestRef.get();
    await requestData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
}
