import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { HiUser } from "react-icons/hi";
import {IoMdArrowDropdown} from "react-icons/io";
import { useState } from "react";
import { TbLogout } from "react-icons/tb";
import Swal from "sweetalert2";

export default function Navbar() {
  const { currentUser } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/user";
      }
    });
  };

  return (
    <div className="bg-white border-b-2 border-gray-300 -mb-2">
      <div className="">
        <div className="relative flex items-center justify-between h-[60px]  ">
          <div className="absolute inset-y-0 right-0 flex items-center mr-10   ">
            <HiUser className="mr-1" size={20} />
            {currentUser.email}
            <IoMdArrowDropdown
              className="ml-1 cursor-pointer mt-1"
              size={20}
              onClick={() => {
                setOpen(!open);
              }}
            />
          </div>
          {open ? (
            <div className="absolute right-10 top-10  bg-white border-2 rounded-md p-2  mt-2">
              <div className="flex justify-end ">
                <label class="inline-flex relative items-center mr-7 cursor-pointer  hover:text-bold"
                onClick={handleLogout}
                >
                  <span>
                    <TbLogout size={20} className="mt-[1px] mr-1" />
                  </span>
                  <span>Log out</span>
                </label>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
