import "./App.css";
import Layout from "./components/Layout";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Message from "./pages/message";
import Employees from "./pages/employees";
import User from "./pages/u";
import CheckIn from "./pages/checkIn";
import CheckInList from "./pages/checkInList";
import Shift from "./pages/shift";
import ShiftList from "./pages/shiftList";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { useEffect } from "react";
import { getUserById } from "./model/user";
import TimeSheet from "./pages/timeSheet";
import AddEmployee from "./pages/addEmployee";
import TimeSheetList from "./pages/timeSheetList";
import AddAdmin from "./pages/addAdmin";
import AdminList from "./pages/adminList";
import TaskSheetList from "./pages/taskSheet";
import ClientList from "./pages/clientLIst";
import UserSheet from "./pages/userSheet";
import Employee from "./pages/employee";
import RequestSheet from "./pages/requestSheet";
import UserProfile from "./pages/userProfile";

function App() {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/user" />;
  };

  useEffect(() => {
    const notLoginPath = ["/admin", "/user", "/tasksheet"];
    const path = window.location.pathname;
    const getUserData = async () => {
      const userData = await getUserById(currentUser.uid);

      // console.log(userData);
      if (!userData && !notLoginPath.includes(path)) {
        window.location.pathname = "/user";
      } else if (!path.includes("/user") && !userData.admin) {
        window.location.href = "/user";
      }
      if ((path === "/user/" || path === "/user") && userData) {
        window.location.href = "/user/main";
      }
      if ((path === "/admin/" || path === "/admin") && userData) {
        window.location.href = userData.admin
          ? "/admin/dashboard"
          : "/user/main";
      }
      if (path === "/tasksheet?") {
        window.location.reload();
      }
    };
    if (path === "/") {
      window.location.href = "/user";
    }
    if (currentUser) {
      getUserData();
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/message" element={<Message />} />
        <Route path="/user" element={<User />} />
        {/* <Route path="/user/check-in" element={<CheckIn />} />
        <Route path="/user/shift" element={<Shift />} /> */}
        {/* <Route path="/user/timesheet" element={<TimeSheet />} /> */}
        <Route path="/tasksheet" element={<TaskSheetList />} />
        <Route
          path="/user/timesheet"
          element={
            <RequireAuth>
              <TimeSheet />
            </RequireAuth>
          }
        />
        <Route
          path="/user/shift"
          element={
            <RequireAuth>
              <Shift />
            </RequireAuth>
          }
        />
        <Route
          path="/user/profile"
          element={
            <RequireAuth>
              <UserProfile />
            </RequireAuth>
          }
        />
        <Route
          path="/user/main"
          element={
            <RequireAuth>
              <CheckIn />
            </RequireAuth>
          }
        />
        <Route
          path="/user/tasklist"
          element={
            <RequireAuth>
              <UserSheet />
            </RequireAuth>
          }
        />
        <Route
          element={
            <RequireAuth>
              <Layout />
            </RequireAuth>
          }
        >
          <Route
            path="/admin/dashboard"
            element={
              <RequireAuth>
                <Home />
              </RequireAuth>
            }
          />
          {/* <Route path="/home" element={<Home />} /> */}
          <Route
            path="/admin/checkinlist"
            element={
              <RequireAuth>
                <CheckInList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/requestsheet"
            element={
              <RequireAuth>
                <RequestSheet />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/timesheetlist"
            element={
              <RequireAuth>
                <TimeSheetList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/shiftlist"
            element={
              <RequireAuth>
                <ShiftList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/employees"
            element={
              <RequireAuth>
                <Employees />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/addemployee"
            element={
              <RequireAuth>
                <AddEmployee />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/employee"
            element={
              <RequireAuth>
                <Employee />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/addadmin"
            element={
              <RequireAuth>
                <AddAdmin />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/adminlist"
            element={
              <RequireAuth>
                <AdminList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/clientlist"
            element={
              <RequireAuth>
                <ClientList />
              </RequireAuth>
            }
          />
          <Route
            path="/admin/employees"
            element={
              <RequireAuth>
                <Employees />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
