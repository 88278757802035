import React, { useEffect } from "react";
import { useState } from "react";
import Loading from "../components/loading";
import { firestore } from "../firebase";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import axios from "axios";
import { getAllClients } from "../model/client";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { MdOutlineDeleteForever } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";

export default function ClientList() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [client, setClient] = useState({
    name: "",
    rate: "",
    country: "",
    currency: "",
  });

  const [countries, setCountries] = useState([]);

  const [currencies, setCurrencies] = useState([]);

  const handleChange = (e) => {
    setClient({ ...client, [e.target.name]: e.target.value });
  };

  const onChangeField = (field, value) => {
    setClient({ ...client, [field]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clients = await getAllClients();
        setItems(clients);
        setIsLoaded(true);
      } catch (error) {
        console.log(error);
      }
    };
    if (items.length === 0) {
      fetchData();
    }
    axios.get("https://restcountries.com/v3.1/all").then((res) => {
      const list = res.data.map((country) => {
        return { value: country.name.common, label: country.name.common };
      });
      const other = { value: "Other", label: "Other" };
      const newList = [...list, other];
      setCountries(newList);
    });
    const currenciesList = [];
    const getCurrencies = async () => {
      const currency = await firestore.collection("currencies").get();
      currency.forEach((doc) => {
        currenciesList.push({ value: doc.data().name, label: doc.data().name });
      });
      setCurrencies(currenciesList);
    };
    getCurrencies();
  }, []);

  const onClickEdit = async (id) => {
    const client = await firestore.collection("clients").doc(id).get();
    setClient({ ...client.data(), id: client.id });
    setShowModalEdit(true);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      await firestore.collection("clients").doc(client.id).update(client);
      Swal.fire({
        icon: "success",
        title: "Client updated successfully",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.reload();
          setShowModalEdit(false);
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        icon: "warning",
        text: "Are you sure, you want to delete this client?",
        showCancelButton: true,
        backdrop: "rgba(120,120,120,0.5)",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          firestore.collection("clients").doc(id).delete();
          Swal.fire({
            icon: "success",
            title: "Client deleted successfully",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleAddClient = async (e) => {
    e.preventDefault();
    try {
      const addClientRef = firestore.collection("clients");
      await addClientRef.add({
        ...client,
      });
      Swal.fire({
        title: "Success",
        text: "Client added successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const { SearchBar, ClearSearchButton } = Search;

  const button = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="flex gap-1">
        <button
          className="btn btn-primary  text-sm "
          onClick={() => {
            onClickEdit(row.id);
          }}
        >
          <div className="flex ">
            <AiTwotoneEdit size={20} />
          </div>
        </button>
        <button
          className="btn btn-danger text-sm"
          onClick={() => {
            handleDelete(row.id);
          }}
        >
          <div className="flex ">
            <MdOutlineDeleteForever size={20} />
          </div>
        </button>
      </div>
    );
  };

  const clientColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "country",
      text: "Country",
      sort: true,
    },
    {
      dataField: "rate",
      text: "Rate",
      sort: true,
    },
    {
      dataField: "currency",
      text: "Currency",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      formatter: button,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const addCurrency = async (value) => {
    try {
      const addCurrencyRef = firestore.collection("currencies");
      await addCurrencyRef.add({
        name: value,
      });
      Swal.fire({
        title: "Success",
        text: "Currency added successfully",
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: items.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const defaultSorted = [{
    dataField: 'name',
    order: 'asc'
  }];

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return (
      <div className="mt-[200px]">
        <Loading />
      </div>
    );
  } else {
    return (
      <>
        {showModalEdit ? (
          <>
            <div
              className={`justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}
            >
              <div className="relative my-6 mx-auto  ">
                <div className="border-2 border-gray-400 rounded-lg shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-center  p-3 pt-4  rounded-t">
                    <h2 className="text-2xl font-bold ml-2">Client data</h2>
                  </div>
                  <form class="w-full  p-1 px-4">
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-2">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Client Name
                        </label>
                        <input
                          class="appearance-none block w-full h-10  text-gray-700 border border-gray-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-client-name"
                          type="text"
                          placeholder="MB"
                          name="name"
                          onChange={handleChange}
                          value={client.name}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3 mt-2">
                        <label
                          class="block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Country
                        </label>
                        <Select
                          className="block tracking-wide max-w-[350px] bg-gray-200 text-gray-700 text-xs  mb-2"
                          options={countries}
                          classNamePrefix="select"
                          onChange={(e) =>
                            onChangeField("country", e ? e.value : null)
                          }
                          isSearchable={true}
                          isClearable={true}
                          name="country"
                          value={countries.find(
                            (option) => option.value === client.country || ""
                          )}
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          class="block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Rate
                        </label>
                        <input
                          class="appearance-none block w-full h-10 text-gray-700 border border-gray-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-rate"
                          type="text"
                          placeholder="rate"
                          name="rate"
                          onChange={handleChange}
                          value={client.rate}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label
                          class="block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Currency{" "}
                          {client.currency === undefined
                            ? ""
                            : "(Current: " + client.currency + ")"}
                        </label>
                        <CreatableSelect
                          className="block tracking-wide max-w-[350px] bg-gray-200 text-gray-700 text-xs  mb-2"
                          options={currencies}
                          classNamePrefix="select"
                          onChange={(e) =>
                            onChangeField("currency", e ? e.value : null)
                          }
                          isSearchable={true}
                          isClearable={true}
                          name="currency"
                          value={currencies.label}
                          onCreateOption={(value) => addCurrency(value)}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="flex items-center justify-end p-2 pb-4 rounded-b">
                    <button
                      className="!bg-red-400 text-white hover:!bg-red-600 font-semibold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModalEdit(false)}
                    >
                      Close
                    </button>
                    <button
                      className="!bg-emerald-500 text-white hover:!bg-emerald-600 font-semibold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleEdit}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        {showModal ? (
          <>
            <div className="justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative my-6 mx-auto  ">
                <div className="border-2 border-gray-400 rounded-lg shadow-2xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div className="flex items-center justify-center  p-3 pt-4  rounded-t">
                    <h2 className="text-2xl font-bold ml-2">Client data</h2>
                  </div>
                  <form class="w-full  p-1 px-4">
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-2">
                        <label
                          class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Client Name
                        </label>
                        <input
                          class="appearance-none block w-full h-10  text-gray-700 border border-gray-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-client-name"
                          type="text"
                          placeholder="Client Name"
                          name="name"
                          onChange={handleChange}
                          value={client.name}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3 mt-2">
                        <label
                          class="block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Country
                        </label>
                        <Select
                          className="block tracking-wide max-w-[350px] bg-gray-200 text-gray-700 text-xs  mb-2"
                          options={countries}
                          classNamePrefix="select"
                          onChange={(e) =>
                            onChangeField("country", e ? e.value : null)
                          }
                          isSearchable={true}
                          isClearable={true}
                          name="country"
                          value={countries.find(
                            (option) => option.value === client.country || ""
                          )}
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label
                          class="block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-first-name"
                        >
                          Rate
                        </label>
                        <input
                          class="appearance-none block w-full h-10 text-gray-700 border border-gray-600 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="grid-rate"
                          type="text"
                          placeholder="rate"
                          name="rate"
                          onChange={handleChange}
                          value={client.rate}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label
                          class="block uppercase  tracking-wide text-gray-700 text-xs font-bold mb-2"
                          for="grid-last-name"
                        >
                          Currency{" "}
                        </label>
                        <CreatableSelect
                          className="block tracking-wide max-w-[350px] bg-gray-200 text-gray-700 text-xs  mb-2"
                          options={currencies}
                          classNamePrefix="select"
                          onChange={(e) =>
                            onChangeField("currency", e ? e.value : null)
                          }
                          isSearchable={true}
                          isClearable={true}
                          name="currency"
                          value={currencies.label}
                          onCreateOption={(value) => addCurrency(value)}
                        />
                      </div>
                    </div>
                  </form>
                  <div className="flex items-center justify-end p-2 pb-4 rounded-b">
                    <button
                      className="!bg-red-400 text-white hover:!bg-red-600 font-semibold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <button
                      className="!bg-emerald-500 text-white hover:!bg-emerald-600 font-semibold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-4 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={handleAddClient}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
          </>
        ) : null}
        <div className="mt-10 mx-10 flex justify-between">
          <div className="text-3xl font-bold">Client</div>
          <div className="mb-3">
            <button
              className="!bg-green-600 text-white p-2 rounded-[5px] hover:!bg-green-700 cursor-pointer"
              type="button"
              onClick={() => setShowModal(true)}
            >
              Add Client
            </button>
          </div>
        </div>
        <div className=" mx-10 mb-10">
          <ToolkitProvider
            keyField="id"
            data={items}
            columns={clientColumns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} className="!rounded-[5px] " />
                <ClearSearchButton
                  {...props.searchProps}
                  className="btn border ml-3 p-2 hover:bg-gray-600 hover:text-white text-sm "
                />
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  striped
                  hover
                  condensed
                  classes="mt-3 "
                  defaultSorted={defaultSorted}
                />
              </div>
            )}
          </ToolkitProvider>
        </div>
      </>
    );
  }
}
