import BootstrapTable from "react-bootstrap-table-next";
import { useEffect, useState } from "react";
import { getAllCheckin } from "./../model/checkIn";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import moment from "moment";

export default function CheckInList() {

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const data = await getAllCheckin();
      data.forEach((item) => {
        list.push({
          ...item,
          checkInDateTime: moment(item.checkInTime).format("HH:mm a"),
          checkOutDateTime: item.checkOutTime === "" ? "-" : moment(item.checkOutTime).format("HH:mm a"),
        });
      });
      setItems(list);
    };
    if (items.length === 0) {
      fetchData();
    }
  }, []);
  
  

  const { SearchBar, ClearSearchButton } = Search;

  const columns = [
    {
      dataField: "date",
      text: "Date",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "checkInDateTime",
      text: "Check In",
      sort: true,
    },
    {
      dataField: "checkOutDateTime",
      text: "Check Out",
      sort: true,
    },
  ];

 const customTotal = (from, to, size) => (
   <span className="react-bootstrap-table-pagination-total ml-3">
     Showing {from} to {to} of {size} Results
   </span>
 );

 const options = {
   paginationSize: 5,
   pageStartIndex: 1,
   // alwaysShowAllBtns: true, // Always show next and previous button
   // withFirstAndLast: false, // Hide the going to First and Last page button
   // hideSizePerPage: true, // Hide the sizePerPage dropdown always
   // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
   firstPageText: "First",
   prePageText: "Back",
   nextPageText: "Next",
   lastPageText: "Last",
   nextPageTitle: "First page",
   prePageTitle: "Pre page",
   firstPageTitle: "Next page",
   lastPageTitle: "Last page",
   showTotal: true,
   paginationTotalRenderer: customTotal,
   disablePageTitle: true,
   sizePerPageList: [
     {
       text: "10",
       value: 10,
     },
     {
       text: "15",
       value: 15,
     },
     {
       text: "20",
       value: 20,
     },
     {
       text: "All",
       value: items.length,
     },
   ], // A numeric array is also available. the purpose of above example is custom the text
 };
  

  return (
    <>
      <div className="mt-10 mx-10 ">
        <div className="text-3xl font-bold">Check In List</div>
      </div>
      <div className="mt-10 mx-10 mb-10 ">
        <ToolkitProvider keyField="id" data={items} columns={columns} search   >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} className="!rounded-[5px] " />
              <ClearSearchButton
                {...props.searchProps}
                className="btn border ml-3 p-2 hover:bg-gray-600 hover:text-white text-sm "
              />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                condensed
                classes="mt-3 "
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}
