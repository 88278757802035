import { firestore } from "../firebase";

export const getAllClients = async () => {
  try {
    let list = [];
    const clientRef = await firestore.collection("clients");
    const clientData = await clientRef.get();
    await clientData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getAllProjects = async () => {
  try {
    let list = [];
    const projectRef = await firestore
      .collection("projects")
    const projectData = await projectRef.get();
    await projectData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const getAllTasks = async () => {
  try {
    let list = [];
    const taskRef = await firestore
      .collection("taskTitles")
    const taskData = await taskRef.get();
    await taskData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export const getAllClientProjectTask = async () => {
  try {
    let list = [];
    let list2 = [];
    let list3 = [];
    const clientRef = await firestore.collection("clients");
    const clientData = await clientRef.get();
    await clientData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    const projectRef = await firestore
      .collection("projects")
    const projectData = await projectRef.get();
    await projectData.forEach(async (doc) => {
      list2.push({ ...doc.data(), id: doc.id });
    });
    const taskRef = await firestore
      .collection("taskTitles")
    const taskData = await taskRef.get();
    await taskData.forEach(async (doc) => {
      list3.push({ ...doc.data(), id: doc.id });
    });
    return { list, list2, list3 };
  }
  catch (error) {
    console.log(error);
    return [];
  }
}
