import { AuthContext } from "../context/AuthContext";
import { useState, useEffect, useContext } from "react";
import { getUserById } from "../model/user";
import { FaRegUserCircle } from "react-icons/fa";
import Swal from "sweetalert2";
import { firestore } from "../firebase";
import emailjs from "emailjs-com";
import moment from "moment";
import Loading from "../components/loading";

export default function Shift() {
  const [enabled, setEnabled] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [data, setData] = useState({
    name: "",
    email: "",
    startDate: "",
    endDate: "",
    reason: "",
    note: "",
    uid: "",
  });
  const [loading, setLoading] = useState(true);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setLoading(true);
    const getUserData = async () => {
      const userData = await getUserById(currentUser.uid);
      setUser(userData);
      setData({
        ...data,
        name: userData.firstName + " " + userData.lastName,
        email: userData.email,
        reason: "Sick Day",
        uid: userData.id,
      });
      setLoading(false);
    };
    getUserData();
  }, []);


  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/user";
      }
    });
  };

  const handleSubmit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to submit?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // console.log("submit");
        const dayOffRef = await firestore.collection("dayOff");
        dayOffRef.add({
          ...data,
          status: "Pending",
          startDate: new Date(data.startDate).valueOf(),
          endDate: new Date(data.endDate).valueOf(),
          fullDay: enabled ? true : false,
        });
        submitInfo();
        Swal.fire("Submitted!", "Your request has been submitted.");
      }
    });
  };


  const submitInfo = () => {
    const emailContent = {
      from_name: data.name,
      from_mail: data.email,
      startDate: moment(new Date(data.startDate)).format("DD/MM/YYYY HH:mm a"),
      endDate: moment(new Date(data.endDate)).format("DD/MM/YYYY HH:mm a"),
      reason: data.reason,
      note: data.note,
      date: moment(new Date()).format("DD/MM/YYYY HH:mm a"),
    };

    emailjs
      .send(
        "service_x464ags",
        "template_j0szqgv",
        emailContent,
        "LTTy0v98fox0N5QBZ"
      )
      .then((result) => {
        console.log(result.text);
      });
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-tl from-blue-200 to-blue-900 ">\
        {loading  ? (
          <div className="!h-screen !justify-center !items-center !align-middle flex">
          <Loading />
        </div>
        ) : (
        <div className="">
          <div className="sm:px-[50px] px-[20px] pt-[20px] pb-[20px]  text-left bg-white shadow-lg rounded-xl">
            <h3 className="text-2xl font-bold text-center">Request Day Off</h3>
            <form action="">
              <div className="mt-3">
                    <div className="gap-5 border-2 border-gray-300 bg-gray-100 rounded-[10px] md:p-6 s5m:p-3 ">
                  <div className="flex justify-between w-100 sm:gap-5 ">
                    <div className="flex gap-3 justify-center items-center">
                      <div className="mt-[6px]">
                            <FaRegUserCircle className="text-5xl text-gray-600 sm:block hidden " />
                      </div>
                          <div className="font-semibold md:text-xl s5m:text-md text-sm md:mt-0 s5m:mt-2">
                        <div>
                          {user.firstName} {user.lastName}{" "}
                        </div>
                        <div>{user.email}</div>
                      </div>
                    </div>
                    <div>
                      <div
                            className="text-center text-[10px] sm:text-[15px] ml-3 mt-[6px] cursor-pointer font-medium text-white bg-gray-600 hover:bg-white hover:!text-gray-600 border-2 border-gray-600 rounded-[100px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-2 sm:py-0 mb-1"
                        onClick={handleLogout}
                      >
                        Log out
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-2 bg-gray-100 border-gray-300 p-4 rounded-[10px] mt-2">
                <div className="flex justify-end">
                  <label className="inline-flex relative items-center mr-5 cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={enabled}
                      readOnly
                    />
                    <div
                      onClick={() => {
                        setEnabled(!enabled);
                      }}
                      className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                    ></div>
                    <span className="ml-2 text-sm font-medium text-gray-900">
                      All day
                    </span>
                  </label>
                </div>
                <div className="sm:flex  gap-4 mt-2 ">
                  <div>
                    <div className="font-semibold mb-1">
                      <label htmlFor="dateStart">Start Date</label>
                    </div>
                    <div>
                      <input
                        type={enabled ? "date" : "datetime-local"}
                        className="rounded-[10px] border border-gray-300"
                        name="startDate"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="font-semibold mb-1">
                      <label htmlFor="dateStart">End Date</label>
                    </div>
                    <div>
                      <input
                        type={enabled ? "date" : "datetime-local"}
                        className="rounded-[10px]  border border-gray-300"
                        name="endDate"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <label
                    className="block font-semibold mb-1"
                    htmlFor="grid-martial-status"
                  >
                    Reason
                  </label>
                  <div className="relative">
                    <select
                      className="block appearance-none w-full  border  text-gray-700 py-3 px-4 pr-8 rounded-[10px] leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      id="grid-martial-status"
                      name="reason"
                      onChange={handleChange}
                    >
                      <option>Sick Day</option>
                      <option>Parental Leave</option>
                      <option>Unpaid</option>
                      <option>Vacation</option>
                      <option>Off</option>
                    </select>
                  </div>
                </div>
                <div className="mt-1">
                  <span className="font-semibold ">Note</span>
                  <textarea
                    className="w-full mt-1 resize-x rounded-md   border   focus:bg-white focus:border-gray-500"
                    name="note"
                    onChange={handleChange}
                  ></textarea>
                </div>
                <div>
                  <div
                    className=" px-6 font-semibold py-3 mt-3 text-white mb-2 bg-blue-600 text-center rounded-[100px] hover:bg-blue-700 w-100 cursor-pointer"
                    onClick={handleSubmit}
                  >
                    Submit
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        ) }
      </div>
    </>
  );
}
