import { firestore } from "./../firebase";

export const getAllRequests = async () => {
  try {
    let list = [];
    const requestsRef = await firestore.collection("requests");
    const requestsData = await requestsRef.get();
    await requestsData.forEach(async (doc) => {
      list.push({ ...doc.data(), id: doc.id });
    });
    return list;
  } catch (error) {
    console.log(error);
    return [];
  }
};
