import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getAllDayOff,
  updateStatusDayOffById,
  plusSickDay,
  plusVacationDay,
  plusUnpaidDay,
  plusParentalDay,
  plusOtherDay,
} from "./../model/dayOff";
import moment from "moment";
import paginationFactory from "react-bootstrap-table2-paginator";
import Swal from "sweetalert2";
import ToolkitProvider, {
  Search,
  // CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
// import filterFactory, {
//   textFilter,
//   dateFilter,
// } from "react-bootstrap-table2-filter";
import emailjs from "emailjs-com";

export default function ShiftList() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      const data = await getAllDayOff();
      // console.log(data);
      data.forEach((item) => {
        list.push({
          ...item,
          startDateTime:
            item.fullDay === true
              ? moment(item.startDate).format("MM/DD/YYYY 00:00 A")
              : moment(item.startDate).format("MM/DD/YYYY HH:mm a"),
          endDateTime:
            item.fullDay === true
              ? moment(item.endDate).format("MM/DD/YYYY 23:59 ") + "PM"
              : moment(item.endDate).format("MM/DD/YYYY HH:mm a"),
        });
      });
      const sortedList = Object.values(list).sort((item) => {
        return item.status === "Pending" ? -1 : 1;
      });
      // console.log(sortedList);
      setItems(sortedList);
    };

    if (items.length === 0) {
      fetchData();
    }
  }, []);

  const { SearchBar, ClearSearchButton } = Search;
  // const { ExportCSVButton } = CSVExport;
  // let nameFilter;
  // let priceFilter;
  // let stockDateFilter;

  const button = (cell, row, rowIndex, formatExtraData) => {
    return row.status === "Pending" ? (
      <div className="flex gap-1">
        <button
          className="btn btn-primary text-sm"
          onClick={() => {
            const submitInfo = () => {
              const emailContent = {
                to_email: row.email,
                to_name: row.name,
                startDate: row.startDateTime,
                endDate: row.endDateTime,
                status: "Approved",
                reason: row.reason,
                note: row.note,
                date: moment(new Date()).format("DD/MM/YYYY HH:mm a"),
              };

              emailjs
                .send(
                  "service_x464ags",
                  "template_ftg3axx",
                  emailContent,
                  "LTTy0v98fox0N5QBZ"
                )
                .then((result) => {
                  console.log(result.text);
                });
            };
            Swal.fire({
              text: "Please confirm to approve this day off request",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Approve",
            }).then((result) => {
              if (result.isConfirmed) {
                updateStatusDayOffById(row.id, "Approved");
                  submitInfo();
                if (row.reason === "Sick Day") {
                  plusSickDay(row.uid);
                } else if (row.reason === "Vacation") {
                  plusVacationDay(row.uid);
                } else if (row.reason === "Unpaid") {
                  plusUnpaidDay(row.uid);
                } else if (row.reason === "Parental Leave") {
                  plusParentalDay(row.uid);
                } else if (row.reason === "Off") {
                  plusOtherDay(row.uid);
                }
                Swal.fire(
                  "Approved!",
                  "Day off request has been approved.",
                  "success"
                ).then(() => {
                  window.location.reload();
                });
              }
            });
          }}
        >
          Approve
        </button>
        <button
          className="btn btn-danger text-sm"
          onClick={() => {
            const submitInfo = () => {
              const emailContent = {
                to_email: row.email,
                to_name: row.name,
                startDate: row.startDateTime,
                endDate: row.endDateTime,
                status: "Rejected",
                date: moment(new Date()).format("DD/MM/YYYY HH:mm a"),
              };

              emailjs
                .send(
                  "service_x464ags",
                  "template_ftg3axx",
                  emailContent,
                  "LTTy0v98fox0N5QBZ"
                )
                .then((result) => {
                  console.log(result.text);
                });
            };
            Swal.fire({
              text: "Please confirm to reject this day off request",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Reject",
            }).then((result) => {
              if (result.isConfirmed) {
                updateStatusDayOffById(row.id, "Rejected");
                submitInfo();
                Swal.fire(
                  "Rejected!",
                  "Day off request has been rejected.",
                  "success"
                ).then(() => {
                  window.location.reload();
                });
              }
            });
          }}
        >
          Rejected
        </button>
      </div>
    ) : (
      ""
    );
  };

  const dayOffColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "startDateTime",
      text: "Start Date",
      sort: true,
    },
    {
      dataField: "endDateTime",
      text: "End Date",
      sort: true,
    },
    {
      dataField: "reason",
      text: "Reason",
      sort: true,
    },
    {
      dataField: "note",
      text: "Note",
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      formatter: button,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  // const handleClick = () => {
  //   nameFilter("");
  //   priceFilter("");
  //   stockDateFilter();
  // };

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: items.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  return (
    <>
      <div className="mt-10 mx-10 flex justify-between">
        <div className="text-3xl font-bold">Time Off List</div>
      </div>
      <div className="my-10 mx-10">
        <ToolkitProvider
          keyField="id"
          data={items}
          columns={dayOffColumns}
          search
          exportCSV={{ onlyExportFiltered: true, exportAll: false }}
        >
          {(props) => (
            <div>
              <SearchBar {...props.searchProps} className="!rounded-[5px] " />
              {/* <button className="btn btn-lg btn-primary" onClick={handleClick}>
                {" "}
                Clear all filters{" "}
              </button>
              <ExportCSVButton {...props.csvProps}>Export CSV</ExportCSVButton> */}
              <ClearSearchButton
                {...props.searchProps}
                className="btn border ml-3 p-2 hover:bg-gray-600 hover:text-white text-sm "
              />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                condensed
                classes="mt-3 "
                // filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
    </>
  );
}
