import React from "react";
import { getAllRequests } from "../model/timeSheet";
import { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { firestore } from "../firebase";
import  {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

export default function RequestSheet() {
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    const getRequests = async () => {
      const requests = await getAllRequests();
      setRequests(requests);
    };
    getRequests();
  }, []);


  const handleApprove = async (id, oldId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const taskRef = firestore.collection("requests").doc(id);
        const newTaskData = taskRef
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              const newTask = {
                ...data,
                request: "approved",
              };
              firestore.collection("Tasks").doc(oldId).set(newTask);
              taskRef.delete();
              Swal.fire(
                "Approved!",
                "Your request has been approved.",
                "success"
              );
            } else {
              Swal.fire(
                "Error!",
                "Your request has not been approved.",
                "error"
              );
            }
          })
          .catch((error) => {
            console.log("Error getting document:", error);
          });
      }
    });
  };

  const handleReject = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, reject it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const taskRef = firestore.collection("requests").doc(id);
        taskRef.delete();
        Swal.fire("Rejected!", "Your request has been rejected.", "success");
      }
    });
  };

  const button = (cell, row, rowIndex, formatExtraData) => {
    return (
      <div className="flex gap-1">
        <div>
          <button
            className="btn btn-primary text-sm"
            onClick={() => {
              handleApprove(row.id, row.oldTaskId);
            }}
          >
            <div className="flex">Approve</div>
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger text-sm"
            onClick={() => {
              handleReject(row.id);
            }}
          >
            <div className="flex">Reject</div>
          </button>
        </div>
      </div>
    );
  };

  const dayOffColumns = [
    {
      dataField: "dateTime",
      text: "Date",
      sort: true,
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
    },
    {
      dataField: "project",
      text: "Project",
      sort: true,
    },
    {
      dataField: "taskTitle",
      text: "Task Title",
      sort: true,
    },
    {
      dataField: "taskDescription",
      text: "Description",
      sort: true,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "hours",
      text: "Hours",
      sort: true,
    },
    {
      dataField: "minutes",
      text: "Minutes",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      formatter: button,
    },
  ];

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: requests.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const { SearchBar, ClearSearchButton } = Search;

  return (
    <>
    {requests.length > 0 ? (
      <div className="mb-10 mt-3 mx-10">
        <ToolkitProvider
          keyField="id"
          data={requests}
          columns={dayOffColumns}
          columnToggle
          search
        >
          {(props) => (
            <div>
              <div className="mt-10 mb-10 flex gap-4">
                <div className="text-3xl font-bold ">Request List</div>
              </div>
              <SearchBar {...props.searchProps} className="!rounded-[5px] " />
              <ClearSearchButton
                {...props.searchProps}
                className="btn border ml-3 p-2 hover:bg-gray-600 hover:text-white text-sm "
              />
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory(options)}
                striped
                hover
                condensed
                classes="mt-3 "
                filter={filterFactory()}
              />
            </div>
          )}
        </ToolkitProvider>
      </div> ) : (
        <div className="flex justify-center  h-screen">
            <div className="mt-10 mb-10 flex gap-4">
              <div className="text-2xl font-bold">No Requests </div>
            </div>
        </div>
      )}
    </> 
  );
}
