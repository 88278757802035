import React, { useState, useEffect } from "react";
import { RiDashboardFill, RiFileUserFill } from "react-icons/ri";
import { HiUsers, HiUser } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { MdWorkOff } from "react-icons/md";
import Logo from "../assets/logoMB.png";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import {
  BsArrowLeftShort,
  BsChevronDown,
} from "react-icons/bs";
import { IoMdPersonAdd } from "react-icons/io";
import { BiSpreadsheet } from "react-icons/bi";
import { RiAdminFill } from "react-icons/ri";
import { getAlldayOffPending } from "../model/dayOff";
import {getAllRequests} from "../model/requests"
import {MdEditCalendar} from "react-icons/md"
import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";
import { getUserById } from "../model/user";

export default function Sidebar() {
  const [open, setOpen] = useState(true);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [dayOff, setDayOff] = useState([]);
  const [requests, setRequests] = useState([]);
  const [user, setUser] = useState(null);
  const [menu, setMenu] = useState([]);
  const location = useLocation();
  const { currentUser } = useContext(AuthContext);

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/user";
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const dayOffData = await getAlldayOffPending();
      setDayOff(dayOffData);
      const requestsData = await getAllRequests();
      setRequests(requestsData);
      const userData = await getUserById(currentUser.uid);
      setUser(userData);
          
      if(userData.specificRole === "Client Admin"){
        setMenu([
          {
            title: "Time sheet",
            icon: <BiSpreadsheet />,
            path: "/admin/timesheetlist",
          },
          {
            title: "Client",
            icon: <RiFileUserFill />,
            path: "/admin/clientlist",
          },
          {
            title: "Requests",
            icon: <MdEditCalendar />,
            path: "/admin/requestsheet",
            Notification: requestsData.length,
          },
        ])
      } else if (userData.specificRole === "Hr Admin"){
        setMenu([
          { title: "Dashboard", icon: <RiDashboardFill />, path: "/admin/dashboard" },
          {
            title: "Employee",
            icon: <HiUser />,
            path: "/admin/dashboard",
            submenu: true,
            submenuItems: [
              { title: "Employee List", icon: <HiUsers />, path: "/admin/employees" },
              {
                title: "Add Employee",
                icon: <IoMdPersonAdd />,
                path: "/admin/addemployee",
              },
            ],
          },
          {
            title: "Time Off",
            icon: <MdWorkOff />,
            path: "/admin/shiftlist",
            Notification: dayOffData.length,
          },
        ])
      }
      else if (userData.specificRole === "Super Admin"){
        setMenu([{ title: "Dashboard", icon: <RiDashboardFill />, path: "/admin/dashboard" },
          {
            title: "Employee",
            icon: <HiUser />,
            path: "/admin/dashboard",
            submenu: true,
            submenuItems: [
              { title: "Employee List", icon: <HiUsers />, path: "/admin/employees" },
              {
                title: "Add Employee",
                icon: <IoMdPersonAdd />,
                path: "/admin/addemployee",
              },
              {
                title: "Admin List",
                icon: <RiAdminFill />,
                path: "/admin/adminlist",
              },
              {
                title: "Add Admin",
                icon: <IoMdPersonAdd />,
                path: "/admin/addadmin",
              },
            ],
          },
          {
            title: "Time Off",
            icon: <MdWorkOff />,
            path: "/admin/shiftlist",
            Notification: dayOffData.length,
          },
          {
            title: "Time sheet",
            icon: <BiSpreadsheet />,
            path: "/admin/timesheetlist",
          },
          {
            title: "Client",
            icon: <RiFileUserFill />,
            path: "/admin/clientlist",
          },
          {
            title: "Requests",
            icon: <MdEditCalendar />,
            path: "/admin/requestsheet",
            Notification: requestsData.length,
          }])
      } else {
        setMenu([])
      }
    };
    fetchData();
  }, []);



  const handleMenu = () => {
    setOpen(!open);
    setSubmenuOpen(false);
  };

  return (
    <div className="flex">
      <div
        className={`bg-bg-1 min-h-screen !p-5 ${
          open  ? "w-[280px] !pt-10" : "w-20 !pt-[45px]"
        } relative duration-300`}
      >
        <BsArrowLeftShort
          className={`bg-white text-dark-purple z-40 text-3xl rounded-full absolute -right-3 top-9 border !border-black cursor-pointer ${
            !open && "rotate-180"
          }`}
          onClick={handleMenu}
        />
        <div className="inline-flex">
          <img
            src={Logo}
            alt="logo"
            className={`text-5xl text-white bg-blue-900  rounded cursor-pointer block float-left  duration-500 p-1   ${
              open
                ? "rotate-[360deg] w-[50px] mr-3"
                : "w-[40px] h-[40px] mr-10 "
            }`}
            onClick={() =>
              (window.location.href = "https://marketingbear.com/")
            }
          />
          <h1
            className={`text-white origin-left font-medium text-xl mt-2 ${
              !open && "scale-0"
            }`}
          >
            Marketing Bear
          </h1>
        </div>
        <div className="flex flex-col max-h-screen ">
          <ul className="pt-7">
            {menu?.map((menu, index) => (
              <>
                <Link to={menu.path}>
                  <React.Fragment key={menu.title}>
                    <li
                      className={`text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white hover:text-bg-1 rounded-md ${
                        menu.spacing ? "mt-[50px]" : "mt-2"
                      } ${
                        location.pathname === menu.path &&
                        !menu.submenu &&
                        "bg-gray-600 dark:bg-gray-700"
                      }  
                  `}
                    >
                        <span className="text-2xl inline-flex  float-left">
                          {menu.icon}{" "}
                        
                        </span>
                      <span
                        className={`text-base font-medium flex-1 ${
                          !open && "hidden"
                        }`}
                      >
                        {menu.title}
                      </span>
                      <span>
                        {(menu.Notification  && menu.Notification !== "0") ? (
                          <span className={`bg-red-500  text-white text-[10px] rounded-full w-5 h-5 flex items-center justify-center ${!open ? "ml-[-18px] mt-[-24px]" : "" } `}>
                            {menu.Notification}
                          </span>
                        ) : null }
                      </span>
                      {menu.submenu && (
                        <BsChevronDown
                          className={`text-2xl block float-right duration-500  ${
                            !open && "hidden"
                          } ${submenuOpen && "rotate-180 "}`}
                          onClick={() => setSubmenuOpen(!submenuOpen)}
                        />
                      )}
                    </li>
                    {menu.submenu && (
                      <ul className={`${submenuOpen ? "block" : "hidden"}`}>
                        {menu.submenuItems.map((submenuItem) => (
                          <Link to={submenuItem.path}>
                            <li
                              className={`ml-5 text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white hover:text-bg-1 rounded-md ${
                                menu.spacing ? "mt-[50px]" : "mt-2"
                              } ${
                                location.pathname === submenuItem.path &&
                                "bg-gray-600 dark:bg-gray-700"
                              }
                                `}
                            >
                              <span className="text-2xl block float-left">
                                {submenuItem.icon}
                              </span>
                              <span
                                className={`text-base font-medium flex-1 ${
                                  !open && "hidden"
                                }`}
                              >
                                {submenuItem.title}
                              </span>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                </Link>
              </>
            ))}
          </ul>
          <ul>
            <li
              className=" text-gray-300 text-sm  mt-3 flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white hover:text-bg-1 rounded-md"
              key={1}
              onClick={handleLogout}
            >
              <span className="text-2xl block float-left ml-[1px]">
                <TbLogout />
              </span>
              <span
                className={`text-base font-medium flex-1 ${!open && "hidden"}`}
              >
                Log out
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
