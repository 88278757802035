import { firestore } from "./../firebase";

export const getUserById = async (id) => {
  if(id){
    const userRef = await firestore.collection("users").doc(id).get();
    const userData = userRef.data();
    return {...userData, id: userRef.id};
  } else {
    return null;
  }
};



