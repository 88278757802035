import React from "react";
import { AuthContext } from "../context/AuthContext";
import { useState, useEffect, useContext } from "react";
import { getAllTask } from "../model/timeSheet";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import Swal from "sweetalert2";
import { firestore } from "../firebase";
import { MdOutlineDeleteForever } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import CreatableSelect from "react-select/creatable";
import { getAllClients, getAllProjects, getAllTasks } from "../model/client";
import { getUserById } from "../model/user";
import { FaRegUserCircle } from "react-icons/fa";
import Loading from "../components/loading";

export default function UserSheet() {
  const { currentUser } = useContext(AuthContext);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalRequest, setShowModalRequest] = useState(false);
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [items, setItems] = useState({
    name: "",
    email: "",
    date: "",
    client: "",
    project: "",
    taskTitle: "",
    taskDescription: "",
    hours: "",
    minutes: "",
    taskType: "general",
  });

  const [client, setClient] = useState({
    name: "",
  });
  const [project, setProject] = useState({
    name: "",
  });
  const [taskTitle, setTaskTitle] = useState({
    name: "",
  });

  const onChangeField = (field, value) => {
    setItems({ ...items, [field]: value });
  };

  useEffect(() => {
    setIsLoading(true);
    const taskList = [];
    getAllTask().then((tasks) => {
      tasks.forEach((task) => {
        if (task.userId === currentUser.uid) {
          taskList.push({
            ...task,
            dateTime: moment(task.date).format("DD/MM/YYYY"),
          });
        }
      });
      setTasks(taskList);
    });
    const getUserData = async () => {
      const userData = await getUserById(currentUser.uid);
      setUser(userData);
    };
    getUserData();
    const clientList = [];
    const getClientData = async () => {
      const clientData = await getAllClients();
      clientData.forEach((doc) => {
        clientList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          country: doc.country,
          rate: doc.rate,
        });
      });
      const sortByClientName = clientList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setClient(sortByClientName);
      setIsLoading(false);
    };
    const projectList = [];
    const getProjectData = async () => {
      const projectData = await getAllProjects();
      projectData.forEach((doc) => {
        projectList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          client: doc.client,
        });
      });
      const sortByProjectName = projectList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setProject(sortByProjectName);
      setIsLoading(false);
    };
    const taskTitleList = [];
    const getTaskTitleData = async () => {
      const taskTitleData = await getAllTasks();
      taskTitleData.forEach((doc) => {
        taskTitleList.push({
          value: doc.name,
          label: doc.name,
          id: doc.id,
          project: doc.project,
        });
      });
      const sortByTaskTitleName = taskTitleList.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      setTaskTitle(sortByTaskTitleName);
      setIsLoading(false);
    };
    getClientData();
    getProjectData();
    getTaskTitleData();
  }, []);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total ml-3">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "All",
        value: tasks.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  const handleDelete = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "Are you sure, you want to delete this task?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          firestore.collection("Tasks").doc(id).delete();
          Swal.fire({
            title: "Deleted!",
            text: "Your task has been deleted.",
            icon: "success",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.reload();
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const button = (cell, row, rowIndex, formatExtraData) => {
    return row.dateTime === moment(new Date()).format("DD/MM/YYYY") ? (
      <div className="flex gap-1">
        <div>
          <button
            className="btn btn-primary text-sm"
            onClick={() => {
              handleOpenModalEdit(row.id);
            }}
          >
            <div className="flex">
              <AiTwotoneEdit size={20} />
            </div>
          </button>
        </div>
        <div>
          <button
            className="btn btn-danger text-sm"
            onClick={() => {
              handleDelete(row.id);
            }}
          >
            <div className="flex">
              <MdOutlineDeleteForever size={20} />
            </div>
          </button>
        </div>
      </div>
    ) : (
      <div>
        <button
          className="bg-gray-600 text-white hover:bg-gray-900 px-3 py-2 rounded-lg  text-sm"
          onClick={() => {
            handleOpenModalRequest(row.id);
          }}
        >
          <div className="flex">Request Edit</div>
        </button>
      </div>
    );
  };


  const columns = [
    {
      dataField: "dateTime",
      text: "Date",
      sort: true,
    },
    {
      dataField: "client",
      text: "Client",
      sort: true,
    },
    {
      dataField: "project",
      text: "Project",
      sort: true,
    },
    {
      dataField: "taskTitle",
      text: "Task Title",
      sort: true,
    },
    {
      dataField: "taskDescription",
      text: "Description",
      sort: true,
    },
    {
      dataField: "taskType",
      text: "Task Types",
      sort: true,
    },
    {
      dataField: "hours",
      text: "Hours",
      sort: true,
    },
    {
      dataField: "minutes",
      text: "Minutes",
      sort: true,
    },
    {
      dataField: "button",
      text: "Action",
      sort: true,
      formatter: button,
    },
  ];

  const addClient = async (value) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to add "${value}" to client list ?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const clientRef = await firestore.collection("clients");
        clientRef.add({
          name: value,
        });
        Swal.fire({
          icon: "success",
          title: "Added!",
          text: `New client has been added to client list.`,
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            // window.location.reload();
            clientRef.onSnapshot((querySnapshot) => {
              const clientList = [];
              querySnapshot.forEach((doc) => {
                clientList.push({
                  value: doc.data().name,
                  label: doc.data().name,
                  id: doc.id,
                });
              });
              const sortByClientName = clientList.sort((a, b) => {
                if (a.label < b.label) {
                  return -1;
                }
                if (a.label > b.label) {
                  return 1;
                }
                return 0;
              });
              setClient(sortByClientName);
            });
          }
        });
      }
    });
  };

  const addProject = async (value) => {
    if (items.client === "") {
      Swal.fire("Error!", "Please select client before adding project.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to add "${value}" to project list?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const projectRef = await firestore.collection("projects");
          projectRef.add({
            client: items.client,
            name: value,
          });
          Swal.fire({
            icon: "success",
            title: "Added!",
            text: `New project has been added to project list.`,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
              projectRef.onSnapshot((querySnapshot) => {
                const projectList = [];
                querySnapshot.forEach((doc) => {
                  projectList.push({
                    value: doc.data().name,
                    label: doc.data().name,
                    id: doc.id,
                    client: doc.data().client,
                  });
                });
                const sortByProjectName = projectList.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                });
                setProject(sortByProjectName);
              });
            }
          });
        } else {
          Swal.fire("Cancelled", "Your project has not been added.");
        }
      });
    }
  };

  const addTaskTitle = async (value) => {
    if (items.client === "" && items.project === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select client, project and task before adding task title."
      );
    } else if (items.project === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select project and task before adding task title."
      );
    } else if (items.client === "" && items.task === "") {
      Swal.fire(
        "Error!",
        "Please select client and task before adding task title."
      );
    } else if (items.client === "" && items.project === "") {
      Swal.fire(
        "Error!",
        "Please select client and project before adding task title."
      );
    } else if (items.task === "") {
      Swal.fire("Error!", "Please select task before adding task title.");
    } else if (items.project === "") {
      Swal.fire("Error!", "Please select project before adding task title.");
    } else if (items.client === "") {
      Swal.fire("Error!", "Please select client before adding task title.");
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: `You want to add "${value}" to task list?`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const taskTitleRef = await firestore.collection("taskTitles");
          taskTitleRef.add({
            client: items.client,
            project: items.project,
            name: value,
          });
          Swal.fire({
            icon: "success",
            title: "Added!",
            text: `New task title has been added to task title list.`,
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              // window.location.reload();
              taskTitleRef.onSnapshot((querySnapshot) => {
                const taskTitleList = [];
                querySnapshot.forEach((doc) => {
                  taskTitleList.push({
                    value: doc.data().name,
                    label: doc.data().name,
                    id: doc.id,
                    client: doc.data().client,
                    project: doc.data().project,
                  });
                });
                const sortByTaskTitleName = taskTitleList.sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                });
                setTaskTitle(sortByTaskTitleName);
              });
            }
          });
        } else {
          Swal.fire("Cancelled", "Your task title has not been added.");
        }
      });
    }
  };

  const handleOpenModalEdit = (id) => {
    setShowModalEdit(true);
    const selectedTask = tasks.filter((task) => task.id === id);
    setItems(selectedTask[0]);
  };

  const handleRequest = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to edit?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const taskRef = await firestore.collection("requests");
        taskRef.add({
          ...items,
          request: true,
          oldTaskId: items.id,
        });
        Swal.fire("Success!", "Your request has been submitted.");
        setShowModalRequest(false);
      }
    });
  };

  const handleOpenModalRequest = (id) => {
    setShowModalRequest(true);
    const selectedTask = tasks.filter((task) => task.id === id);
    setItems(selectedTask[0]);
  };

  const handleEdit = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to edit?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const taskRef = await firestore.collection("Tasks");
        taskRef.doc(items.id).update({
          ...items,
        });
        Swal.fire("Edited!", "Your request has been edited.");
        setShowModalEdit(false);
      }
    });
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("user");
        window.location.href = "/user";
      }
    });
  };


  return (
    <>
      {showModalEdit ? (
        <>
          <div className="justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto  ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-2xl font-bold ml-2">Task Info</h2>
                </div>
                <div className="flex">
                  <form class="w-full  p-1 px-3">
                    <div class="flex flex-wrap mt-3 -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold  mt-2">
                          Client
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {items.client}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="client"
                          value={client.value}
                          options={client}
                          onChange={(e) =>
                            onChangeField("client", e ? e.value : null)
                          }
                          onCreateOption={(value) => addClient(value)}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label className="block font-semibold  mt-2">
                          Project
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {items.project}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={project.value}
                          options={Object.values(project).filter(
                            (item) => item.client === items.client
                          )}
                          onChange={(e) =>
                            onChangeField("project", e ? e.value : null)
                          }
                          onCreateOption={(value) => addProject(value)}
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold  mt-2">
                          Task Title
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {items.taskTitle}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={taskTitle.value}
                          options={Object.values(taskTitle).filter(
                            (item) => item.project === items.project
                          )}
                          onChange={(e) =>
                            onChangeField("taskTitle", e ? e.value : null)
                          }
                          onCreateOption={(value) => addTaskTitle(value)}
                        />
                      </div>
                    </div>
                    <div class="flex  mt-3 -mx-3 mb-6">
                      <div class="w-full px-3 mb-6 md:mb-0">
                        <label className="block font-semibold mb-1 mt-1">
                          Description
                        </label>
                        <input
                          type="text"
                          name="description"
                          value={items.taskDescription}
                          className="border-gray-300 rounded-[5px] w-full"
                          onChange={(e) =>
                            onChangeField("taskDescription", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold mb-1 mt-1">
                          Hours
                        </label>
                        <input
                          className="border-gray-300 rounded-[5px] w-full"
                          type="text"
                          name="hours"
                          value={items.hours}
                          onChange={(e) =>
                            onChangeField("hours", e.target.value)
                          }
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label className="block font-semibold mb-1 mt-1">
                          Minutes
                        </label>
                        <input
                          className="border-gray-300 rounded-[5px] w-full"
                          type="text"
                          name="minutes"
                          value={items.minutes}
                          onChange={(e) =>
                            onChangeField("minutes", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full mb-6 md:mb-0">
                      <label
                        className="block font-semibold mb-1 mt-1"
                        for="grid-martial-status"
                      >
                        Task  Type
                      </label>
                      <div className="relative">
                        <select
                          className="border-gray-300 rounded-[5px] w-full"
                          id="grid-department"
                          name="taskType"
                          onChange={(e) =>
                            onChangeField("taskType", e.target.value)
                          }
                          value={items.taskType || ""}
                        >
                          <option>general</option>
                          <option>emergency</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 !bg-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalEdit(false)}
                  >
                    Close
                  </button>
                  <button
                    className="!bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleEdit}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {showModalRequest ? (
        <>
          <div className="justify-center h-screen items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 mx-auto  ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-3 border-b border-solid border-slate-200 rounded-t">
                  <h2 className="text-2xl font-bold ml-2">Task Info</h2>
                </div>
                <div className="flex">
                  <form class="w-full  p-1 px-3">
                    <div class="flex flex-wrap mt-3 -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold  mt-2">
                          Client
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {items.client}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="client"
                          value={client.value}
                          options={client}
                          onChange={(e) =>
                            onChangeField("client", e ? e.value : null)
                          }
                          onCreateOption={(value) => addClient(value)}
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label className="block font-semibold  mt-2">
                          Project
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {items.project}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={project.value}
                          options={Object.values(project).filter(
                            (item) => item.client === items.client
                          )}
                          onChange={(e) =>
                            onChangeField("project", e ? e.value : null)
                          }
                          onCreateOption={(value) => addProject(value)}
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold  mt-2">
                          Task Title
                        </label>
                        <label className="block font-semibold mb-1 ">
                          Current: {items.taskTitle}
                        </label>
                        <CreatableSelect
                          className="basic-single max-w-[350px] rounded-[10px]  border-gray-300"
                          classNamePrefix="select"
                          isSearchable={true}
                          isClearable={true}
                          name="project"
                          value={taskTitle.value}
                          options={Object.values(taskTitle).filter(
                            (item) => item.project === items.project
                          )}
                          onChange={(e) =>
                            onChangeField("taskTitle", e ? e.value : null)
                          }
                          onCreateOption={(value) => addTaskTitle(value)}
                        />
                      </div>
                    </div>
                    <div class="flex  mt-3 -mx-3 mb-6">
                      <div class="w-full px-3 mb-6 md:mb-0">
                        <label className="block font-semibold mb-1 mt-1">
                          Description
                        </label>
                        <input
                          type="text"
                          name="taskDescription"
                          value={items.taskDescription}
                          className="border-gray-300 rounded-[5px] w-full"
                          onChange={(e) =>
                            onChangeField("taskDescription", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div class="flex flex-wrap -mx-3 mb-6">
                      <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                        <label className="block font-semibold mb-1 mt-1">
                          Hours
                        </label>
                        <input
                          className="border-gray-300 rounded-[5px] w-full"
                          type="text"
                          name="hours"
                          value={items.hours}
                          onChange={(e) =>
                            onChangeField("hours", e.target.value)
                          }
                        />
                      </div>
                      <div class="w-full md:w-1/2 px-3">
                        <label className="block font-semibold mb-1 mt-1">
                          Minutes
                        </label>
                        <input
                          className="border-gray-300 rounded-[5px] w-full"
                          type="text"
                          name="minutes"
                          value={items.minutes}
                          onChange={(e) =>
                            onChangeField("minutes", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="w-full mb-6 md:mb-0">
                      <label
                        className="block font-semibold mb-1 mt-1"
                        for="grid-martial-status"
                      >
                        Task  Type
                      </label>
                      <div className="relative">
                        <select
                          className="border-gray-300 rounded-[5px] w-full"
                          id="grid-department"
                          name="taskType"
                          onChange={(e) =>
                            onChangeField("taskType", e.target.value)
                          }
                          value={items.taskType || ""}
                        >
                          <option>general</option>
                          <option>emergency</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 !bg-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModalRequest(false)}
                  >
                    Close
                  </button>
                  <button
                    className="!bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleRequest}
                  >
                    Request
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      <div className="flex justify-center min-h-screen ">
        {isLoading   ?
          <div className="flex justify-center min-h-screen items-center " >
            <Loading />
          </div>
          : (
            <div className="mb-10 mt-3 mx-10">
              <ToolkitProvider
                keyField="id"
                data={tasks}
                columns={columns}
                columnToggle
              >
                {(props) => (
                  <div>
                    <div className="mt-4 mb-2 flex gap-4 justify-center">
                      {/* <div className="text-3xl font-bold mt-10  "></div> */}
                      <div className="flex">
                        <div className="border-2 bg-gray-100 border-gray-300 px-3 py-3 flex rounded-[10px]">
                          <div className="flex justify-between w-100  ">
                            <div className="flex gap-3">
                              <div className="mt-2">
                                <FaRegUserCircle className="text-5xl text-gray-600 s5m:block hidden " />
                              </div>
                              <div className="font-semibold md:text-xl s5m:text-md text-sm md:mt-0 s5m:mt-2  ">
                                <div>
                                  {user.firstName} {user.lastName}{" "}
                                </div>
                                <div>{user.email}</div>
                              </div>
                            </div>
                            <div>
                              <div
                                className="text-center ml-10 s5m:mt-2 mt-1  cursor-pointer text-[10px] s5m:text-[15px] text-white bg-gray-900 hover:bg-gray-700 rounded-[10px] s5m:mr-0 s5m:px-4 px-3 mr-1 py-2"
                                onClick={handleLogout}
                              >
                                Log out
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-2xl font-bold mt-3 mb-4 text-center ">Task List</div>
                    <BootstrapTable
                      {...props.baseProps}
                      pagination={paginationFactory(options)}
                      striped
                      hover
                      condensed
                      classes=""
                      filter={filterFactory()}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          )}
      </div>
    </>
  );
}
