import { useContext, useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Swal from "sweetalert2";
import Logo from "../assets/logoMBBlue.png";
import {getUserById} from "../model/user";

export default function CheckIn() {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = (e) => {
    e.preventDefault();
    setPasswordShown(!passwordShown);
  };
  const navigate = useNavigate();

  const { dispatch } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(async(userCredential) => {
        // Signed in
        const userD = userCredential.user;
        const user = await getUserById(userCredential.user.uid);
        if(user.active === true){
          dispatch({ type: "LOGIN", payload: userD });
          navigate("/user/main");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: "Please contact your administrator",
          });
        }


        // const user = userCredential.user;
        // dispatch({ type: "LOGIN", payload: user });
        // navigate("/user/main");
        // ...
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: "Email or Password is incorrect!",
          confirmButtonColor: "#3085d6",
        })
      });
  };
  return (
    <>
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-tl from-blue-200 to-blue-900 ">
        <div className="px-[40px] pt-[50px] pb-[50px] sm:px-[80px] sm:pt-[120px] sm:pb-[100px]  text-left bg-white shadow-lg rounded-xl">
          <div className="sm:mt-[-50px] ">
            <img src={Logo} alt="logo" className="w-[100px] mx-auto mb-2" /><h3 className="sm:text-3xl text-2xl font-bold text-center">Sign In</h3>
          </div>
          <form action="">
            <div className="mt-10">
              <div>
                <label className="block font-semibold" htmlFor="username">
                  Email
                </label>
                <input
                  type="text"
                  placeholder="Enter your email"
                  className="sm:w-[350px]  px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mt-4">
                <div className="relative w-full mb-3">
                  <label htmlFor="password" className="block font-semibold">
                    Password
                  </label>
                  <input
                    type={passwordShown ? "text" : "password"}
                    name="password"
                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className=" absolute  mt-8 inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-600">
                    <div
                      onClick={togglePassword}
                      className="cursor-pointer text-xl"
                    >
                      {passwordShown ? <BiShow /> : <BiHide />}
                    </div>
                  </div>
                </div>
              </div>

              <div className="items-baseline w-100 mt-10">
                <div
                  className="px-6 py-3 mt-5 text-white bg-blue-600 text-center rounded-[10px] hover:bg-blue-400 w-100 cursor-pointer"
                  // onClick={login}
                  onClick={handleLogin}
                >
                  Login
                </div>
              </div>
            </div>
          </form>
        </div>
        {/* {
          error && <div className="absolute top-0 right-0 mt-10 mr-10 bg-red-500 text-white px-4 py-2 rounded-xl">Invalid Credentials</div>
        } */}
      </div>
    </>
  );
}
